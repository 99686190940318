import ApiService from "@/common/api_service";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { CustomerNormStoreStateInterface } from "../interfaces";
import { NormListItemInterface } from "../norms/interfaces";

export const useCustomerNormStore = defineStore({
    id: "customerNormStore",
    state: (): CustomerNormStoreStateInterface => ({
        chosenCustomers: [],
        customerSimpleList: [],
        customerParams: {
            search: "",
            page: 1,
            sort: "index",
        },
        normList: [],
        params: {
            sort: "index",
            page: 1,
            policy_ids: [],
            type_ids: [],
            search: "",
        },
        customerNormPagination: {
            endOfList: false,
            page: 1,
        },
        customerPagination: {
            endOfList: false,
            page: 1,
        },
        isFetchingNorms: false,
        maxCustomers: 0,
    }),
    getters: {},
    actions: {
        async fetchNormList(): Promise<void> {
            if (!this.isFetchingNorms) {
                this.isFetchingNorms = true;
                this.params.page = this.customerNormPagination.page;
                const response = await ApiService.get({
                    resource: "norms-customers",
                    params: this.params,
                });
                if (!response?.data.links.next) {
                    this.customerNormPagination.endOfList = true;
                }
                if (this.customerNormPagination.page == 1) {
                    this.normList = [];
                }
                this.normList = this.normList.concat(response?.data.data);
                this.isFetchingNorms = false;
            }
        },

        async fetchCustomerSimpleList(): Promise<void> {
            this.customerParams.page = this.customerPagination.page;
            const response = await ApiService.get({
                resource: "customers-simple",
                params: this.customerParams,
            });
            const responseData = response?.data.data;
            if (!response?.data.links.next) {
                this.customerPagination.endOfList = true;
            }
            if (this.customerPagination.page == 1) {
                this.customerSimpleList = [];
            }
            this.maxCustomers = response?.data?.meta?.total ?? 0;
            this.customerSimpleList =
                this.customerSimpleList.concat(responseData);
        },

        async updateCustomerNorm(
            normId: number,
            customerId: number
        ): Promise<AxiosResponse | null> {
            const response = await ApiService.post({
                resource: "norms/" + normId + "/customers/" + customerId,
            });
            return response;
        },

        replaceCustomerNormEntry(responseData: NormListItemInterface): void {
            if (this.normList.length != 0) {
                const normIndex = this.normList.findIndex(
                    (element) => element.id == responseData.id
                );
                if (normIndex > -1) {
                    /*
                     * has to be done like this since the editNorm response does not give a CustomerNormList Item.
                     * It instead gives a norm NormListItem which does not include certain properties which are needed for the list but cant be edited via editNorm
                     */
                    const normItem = this.normList[normIndex];

                    normItem.index = responseData.index as number;
                    normItem.number = responseData.number;
                    normItem.state_icon_url = responseData.state_icon_url;
                    normItem.state_label = responseData.state_label;
                    normItem.status_short_name = responseData.status_short_name;
                    normItem.type_short_name = responseData.type_short_name;
                }
            }
        },
    },
});
