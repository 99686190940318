
import { computed, defineComponent } from "vue";
import { storeToRefs } from "pinia";
import { useNormStore } from "@/store/norms";
import { useBaseDataStore } from "@/store/settings/baseDataStore";
import { compareObjects } from "@/common/compare_objects";
import { BaseDataInterface } from "@/store/settings/interfaces";

export default defineComponent({
    name: "EmailContainer",
    components: {},
    props: {
        open: {
            type: Boolean,
            required: false,
        },
    },
    emits: ["close"],
    setup() {
        const { editNorm, norm } = storeToRefs(useNormStore());
        const { types, states } = storeToRefs(useBaseDataStore());
        const mappedNorm = computed(() => {
            return {
                norm_type_id: types.value.find(
                    (type) => type.id == editNorm.value.norm_type_id
                )?.name,
                number: editNorm.value.number,
                title: editNorm.value.name,
                issue_date: editNorm.value.issue_date,
            };
        });

        const normDiffs = computed(() => {
            return compareObjects(norm.value, editNorm.value);
        });

        function displayValue(key: string, value: any) {
            if (key == "policies") {
                const policieNames: string[] = [];
                value.forEach((policie: BaseDataInterface) => {
                    policieNames.push(policie.name ?? "-");
                });
                return String(policieNames);
            }
            if (key == "norm_status_id") {
                return states.value.find((state) => state.id == value)?.name;
            }
            if (key == "norm_type_id") {
                return types.value.find((type) => type.id == value)?.name;
            }
            return value;
        }

        return {
            mappedNorm,
            editNorm,
            normDiffs,
            displayValue,
        };
    },
});
