import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full flex flex-col overflow-hidden" }
const _hoisted_2 = {
  id: "listHeader",
  class: "md:flex gap-3 w-full mt-6 mb-2 hidden"
}
const _hoisted_3 = {
  key: 0,
  class: "flex justify-center items-center w-full h-full text-grey-darker"
}
const _hoisted_4 = {
  id: "norm_list",
  class: "overflow-y-auto flex flex-col gap-1 h-full pr-1 md:mt-0 mt-4"
}
const _hoisted_5 = {
  key: 1,
  class: "w-full flex justify-center h-8 bg-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow = _resolveComponent("icon-arrow")!
  const _component_list_item = _resolveComponent("list-item")!
  const _component_list_item_mobile = _resolveComponent("list-item-mobile")!
  const _component_loading_animation = _resolveComponent("loading-animation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setFilter('norm_state'))),
        class: "ml-1 hidden md:flex w-8 cursor-pointer"
      }, [
        _cache[7] || (_cache[7] = _createElementVNode("p", { class: "text-sm text-grey-darker" }, "~", -1)),
        _createVNode(_component_icon_arrow, {
          class: _normalizeClass([`${
                        _ctx.params.sort == 'norm_state' ||
                        _ctx.params.sort == '-norm_state'
                            ? 'flex'
                            : 'hidden'
                    } ${_ctx.params.sort == 'norm_state' ? 'rotate-180' : ''}`, "transform scale-75 transition-all"]),
          color: 'grey-dark'
        }, null, 8, ["class"])
      ]),
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setFilter('index'))),
        class: "hidden md:flex w-8 cursor-pointer"
      }, [
        _cache[8] || (_cache[8] = _createElementVNode("p", { class: "text-sm text-grey-darker" }, "#", -1)),
        _createVNode(_component_icon_arrow, {
          class: _normalizeClass([`${
                        _ctx.params.sort == 'index' || _ctx.params.sort == '-index'
                            ? 'flex'
                            : 'hidden'
                    } ${_ctx.params.sort == 'index' ? 'rotate-180' : ''}`, "transform scale-75 transition-all"]),
          color: 'grey-dark'
        }, null, 8, ["class"])
      ]),
      _createElementVNode("div", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setFilter('policy_short_names'))),
        class: "hidden md:flex cursor-pointer w-17"
      }, [
        _cache[9] || (_cache[9] = _createElementVNode("p", { class: "text-sm text-grey-darker" }, "Richtlinie", -1)),
        _createVNode(_component_icon_arrow, {
          class: _normalizeClass([`${
                        _ctx.params.sort == 'policy_short_names' ||
                        _ctx.params.sort == '-policy_short_names'
                            ? 'flex flex-shrink-0'
                            : 'hidden'
                    } ${
                        _ctx.params.sort == 'policy_short_names' ? 'rotate-180' : ''
                    }`, "transform scale-75 transition-all"]),
          color: 'grey-dark'
        }, null, 8, ["class"])
      ]),
      _createElementVNode("div", {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setFilter('status_short_name'))),
        class: "hidden md:flex cursor-pointer w-12"
      }, [
        _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-sm text-grey-darker" }, "Status", -1)),
        _createVNode(_component_icon_arrow, {
          class: _normalizeClass([`${
                        _ctx.params.sort == 'status_short_name' ||
                        _ctx.params.sort == '-status_short_name'
                            ? 'flex flex-shrink-0'
                            : 'hidden'
                    } ${
                        _ctx.params.sort == 'status_short_name' ? 'rotate-180' : ''
                    }`, "transform scale-75 transition-all"]),
          color: 'grey-dark'
        }, null, 8, ["class"])
      ]),
      _createElementVNode("div", {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setFilter('type_short_name'))),
        class: "flex-shrink-0 hidden md:flex cursor-pointer w-28"
      }, [
        _cache[11] || (_cache[11] = _createElementVNode("p", { class: "text-sm text-grey-darker" }, "Art", -1)),
        _createVNode(_component_icon_arrow, {
          class: _normalizeClass([`${
                        _ctx.params.sort == 'type_short_name' ||
                        _ctx.params.sort == '-type_short_name'
                            ? 'flex'
                            : 'hidden'
                    } ${_ctx.params.sort == 'type_short_name' ? 'rotate-180' : ''}`, "transform scale-75 transition-all"]),
          color: 'grey-dark'
        }, null, 8, ["class"])
      ]),
      _createElementVNode("div", {
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setFilter('number'))),
        class: "md:ml-0 ml-11 flex cursor-pointer w-44"
      }, [
        _cache[12] || (_cache[12] = _createElementVNode("p", { class: "text-sm text-grey-darker" }, "Nummer", -1)),
        _createVNode(_component_icon_arrow, {
          class: _normalizeClass([`${
                        _ctx.params.sort == 'number' || _ctx.params.sort == '-number'
                            ? 'flex'
                            : 'hidden'
                    } ${_ctx.params.sort == 'number' ? 'rotate-180' : ''}`, "transform scale-75 transition-all"]),
          color: 'grey-dark'
        }, null, 8, ["class"])
      ]),
      _createElementVNode("div", {
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setFilter('name'))),
        class: "flex cursor-pointer"
      }, [
        _cache[13] || (_cache[13] = _createElementVNode("p", { class: "text-sm text-grey-darker" }, "Titel", -1)),
        _createVNode(_component_icon_arrow, {
          class: _normalizeClass([`${
                        _ctx.params.sort == 'name' || _ctx.params.sort == '-name'
                            ? 'flex'
                            : 'hidden'
                    } ${_ctx.params.sort == 'name' ? 'rotate-180' : ''}`, "transform scale-75 transition-all"]),
          color: 'grey-dark'
        }, null, 8, ["class"])
      ])
    ]),
    (!_ctx.isLoading && _ctx.normList.length == 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("no_search_result")), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.normList, (norm) => {
        return (_openBlock(), _createBlock(_component_list_item, {
          key: norm.id,
          item: norm,
          onClick: ($event: any) => (_ctx.$emit('openDetails', norm.id))
        }, null, 8, ["item", "onClick"]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.normList, (norm) => {
        return (_openBlock(), _createBlock(_component_list_item_mobile, {
          key: norm.id,
          item: norm,
          onOpenDetails: ($event: any) => (_ctx.$emit('openDetails', norm.id))
        }, null, 8, ["item", "onOpenDetails"]))
      }), 128))
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_loading_animation, {
            id: 'loadingAnimation',
            name: 'loadingAnimation',
            class: "mt-2",
            animationStyle: {
                    radius: '12',
                    animationColor: 'grey',
                }
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}