
import { defineComponent, onMounted, ref, watch } from "vue";
import InputCheckbox from "@/components/inputs/inputCheckbox/InputCheckbox.vue";

export default defineComponent({
    name: "contactItemContainer",
    components: { InputCheckbox },
    props: {
        contact: {
            type: Object,
            required: true,
        },
        index: {
            type: [Number],
            required: true,
        },
        clear: {
            type: Boolean,
            required: false,
        },
        selectAll: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["close", "deselect", "changeCheckValue"],
    setup(props, ctx) {
        const checkValue = ref<boolean>(false);
        watch(
            () => props.selectAll,
            () => {
                checkValue.value = props.contact.checked;
            }
        );

        function changeValue(value: boolean) {
            const data = { index: props.index, value: value };
            ctx.emit("changeCheckValue", data);
        }

        onMounted(() => {
            checkValue.value = props.contact.checked;
        });

        return { checkValue, changeValue };
    },
});
