
import IconArrow from "@/components/icons/IconArrowBigBottom.vue";
import ListItem from "./ListItemContainer.vue";
import ListItemMobile from "./ListItemMobileContainer.vue";
import LoadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import { computed, defineComponent, onMounted, onUnmounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useNormStore } from "@/store/norms";
import { useHelpersStore } from "@/store/helpers";

export default defineComponent({
    name: "itemList",
    emits: ["openDetails"],
    components: {
        IconArrow,
        ListItem,
        LoadingAnimation,
        ListItemMobile,
    },
    setup() {
        const { normList, normPagination, params } = storeToRefs(
            useNormStore()
        );
        const { loadingIds } = storeToRefs(useHelpersStore());
        const { setLoadingId, removeLoadingId } = useHelpersStore();
        const { fetchNormList } = useNormStore();

        const isLoading = computed(() => {
            return loadingIds.value.find(
                (element) => element.name == "fetch_norms_loading"
            );
        });

        var typingTimer: ReturnType<typeof setTimeout>;
        watch(
            () => [
                params.value?.search,
                params.value?.policy_ids?.length,
                params.value?.type_ids?.length,
            ],
            () => {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(async () => {
                    const policiesFilterLength =
                        params.value?.policy_ids?.length ?? (0 as number);
                    const typeFilterLength =
                        params.value.type_ids?.length ?? (0 as number);

                    if (policiesFilterLength <= 3 && typeFilterLength <= 3)
                        fetchNorms(true);
                }, 500);
            }
        );

        function setFilter(filter: string) {
            if (params.value?.sort == filter) {
                if (params.value?.sort[0] != "-") {
                    params.value.sort = "-" + params.value?.sort;
                } else {
                    normPagination.value.page = 1;
                    normPagination.value.endOfList = false;
                }
            } else {
                params.value.sort = filter;
            }

            fetchNorms(true);
        }

        // function resetFilters(){
        //     params.value.sort == 'index';
        //     fetchNorms(true);
        // }

        async function fetchNorms(reset: boolean): Promise<void> {
            if (reset) {
                normPagination.value.page = 1;
                normPagination.value.endOfList = false;
            }
            if (!normPagination.value.endOfList) {
                setLoadingId("fetch_norms_loading");
                await fetchNormList();
                removeLoadingId("fetch_norms_loading");
            }
        }

        function handleScroll() {
            if (!normPagination.value.endOfList) {
                let element: HTMLElement | null = document.getElementById(
                    "norm_list"
                ) as HTMLElement;
                if (
                    element?.scrollTop + element?.clientHeight >=
                    element?.scrollHeight
                ) {
                    normPagination.value.page++;
                    fetchNorms(false);
                }
            }
        }

        onMounted(() => {
            params.value.sort == "index";
            fetchNorms(true);
            const list: HTMLElement | null =
                document.getElementById("norm_list");
            list?.addEventListener("scroll", handleScroll);
        });
        onUnmounted(() => {
            const list: HTMLElement | null =
                document.getElementById("norm_list");
            list?.removeEventListener("scroll", handleScroll);
        });
        return {
            setFilter,
            normList,
            params,
            isLoading,
        };
    },
});
