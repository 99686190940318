import { convertBase64ToBlob } from "./convert_b64";

export function generateFormData(data: any, isPut: boolean): FormData {
    const fd = new FormData();
    for (const [key, value] of Object.entries(data)) {
        if (value != null) {
            if (Array.isArray(value)) {
                for (let index = 0; index < value.length; index++) {
                    fd.append(key + "[]", value[index]);
                }
            } else if (typeof value === "object") {
                for (const [key2, value2] of Object.entries(value)) {
                    fd.append(key + "[" + key2 + "]", value2 as string);
                }
            } else {
                if (key != "avatar") {
                    fd.append(key, value as string);
                } else {
                    fd.append(
                        "avatar",
                        convertBase64ToBlob(data.avatar as string),
                        "image.png"
                    );
                }
            }
        }
    }
    if (isPut) fd.append("_method", "put");
    return fd;
}
