
import { computed, defineComponent, onMounted, ref } from "vue";
import InputDefault from "@/components/inputs/inputDefault/InputDefault.vue";
import InputSelect from "@/components/inputs/inputSelect/InputSelect.vue";
import InputTextarea from "@/components/inputs/inputTextarea/InputTextarea.vue";
import LoadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import CreateNormDiffContainer from "./CreateNormDiffContainer.vue";
import { storeToRefs } from "pinia";
import { cloneData } from "@/common/cloneData";
import { useNormStore } from "@/store/norms";
import { useHelpersStore } from "@/store/helpers";
import { useMailStore } from "@/store/settings/mailStore";

export default defineComponent({
    name: "EmailContainer",
    components: {
        InputDefault,
        InputSelect,
        InputTextarea,
        LoadingAnimation,
        CreateNormDiffContainer,
    },
    props: {
        open: {
            type: Boolean,
            required: false,
        },
    },
    emits: ["close"],
    setup() {
        const { fetchMailTypes, fetchMailContent } = useMailStore();
        const { setLoadingId, removeLoadingId } = useHelpersStore();
        const { emailTypes, emailContent } = storeToRefs(useMailStore());
        const { loadingIds } = storeToRefs(useHelpersStore());
        const { mail, editNorm } = storeToRefs(useNormStore());
        const currentView = ref<string>("mail");

        const isLoading = computed(() => {
            return loadingIds.value.find(
                (id) => id.name == "loading_email_content"
            );
        });

        onMounted(async () => {
            if (!editNorm.value.notify_customers) {
                currentView.value = "norm";
            }
            setLoadingId("loading_email_content");
            await fetchMailTypes();
            await fetchMailContent();
            mail.value = cloneData(emailContent.value);
            removeLoadingId("loading_email_content");
        });
        return {
            mail,
            emailTypes,
            isLoading,
            editNorm,
            currentView,
        };
    },
});
