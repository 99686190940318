export function compareObjects(objectA: any, objectB: any): any[] {
    const diffs: any[] = [];

    Object.keys(objectA).forEach((aKey) => {
        if (
            Object.keys(objectB).find((bKey) => aKey == bKey) &&
            JSON.stringify(objectA[aKey]) != JSON.stringify(objectB[aKey])
        ) {
            diffs.push({
                key: aKey,
                oldValue: objectA[aKey],
                newValue: objectB[aKey],
            });
        }
    });
    return diffs;
}
