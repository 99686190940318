import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex ml-3 items-center" }
const _hoisted_2 = { class: "text-sm text-grey-dark w-96" }
const _hoisted_3 = { class: "text-sm text-grey-dark w-64 ml-1" }
const _hoisted_4 = { class: "text-sm text-grey-dark mr-3.5 ml-1 flex-shrink-0" }
const _hoisted_5 = { class: "h-96 pr-1 overflow-y-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_checkbox = _resolveComponent("input-checkbox")!
  const _component_contact_item = _resolveComponent("contact-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("name")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("email")), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("select_all")), 1),
        _createVNode(_component_input_checkbox, {
          id: "checkbox",
          name: "checkbox",
          class: "-mb-5 mr-2.5",
          modelValue: _ctx.selectAll.employee,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectAll.employee) = $event)),
            _cache[1] || (_cache[1] = ($event: any) => (
                        _ctx.selectAllRecipiens('users', _ctx.selectAll.employee)
                    ))
          ]
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userRecipients, (contact, index) => {
          return (_openBlock(), _createBlock(_component_contact_item, {
            key: contact.id,
            contact: contact,
            index: index,
            selectAll: _ctx.selectAll.employee,
            onDeselect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectAll.employee = false)),
            onChangeCheckValue: _ctx.changeCheckValue
          }, null, 8, ["contact", "index", "selectAll", "onChangeCheckValue"]))
        }), 128))
      ])
    ])
  ]))
}