import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-9 bg-white-dark rounded-md mt-1 flex items-center justify-between pl-3" }
const _hoisted_2 = { class: "text-sm text-grey-darkest md:w-96 w-20 mr-5 truncate" }
const _hoisted_3 = { class: "text-sm text-grey-darkest md:w-64 w-40 mr-5 truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_checkbox = _resolveComponent("input-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.contact.name), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.contact.email), 1),
    _createVNode(_component_input_checkbox, {
      modelValue: _ctx.checkValue,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkValue) = $event)),
        _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeValue(_ctx.checkValue)))
      ],
      id: "checkbox",
      name: "checkbox",
      class: "mt-6 md:pl-28"
    }, null, 8, ["modelValue"])
  ]))
}