import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "filter",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1729",
      "data-name": "Rechteck 1729",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "filter-2",
      "data-name": "filter",
      d: "M145,111a1,1,0,0,1-1-1v-6.657l-6.79-8.729A1,1,0,0,1,138,93h18a1,1,0,0,1,.79,1.614L150,103.343V107a1,1,0,0,1-.4.8l-4,3A1,1,0,0,1,145,111Zm-4.956-16,5.746,7.386A1,1,0,0,1,146,103v5l2-1.5V103a1,1,0,0,1,.21-.614L153.956,95Z",
      transform: "translate(-135 -90)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}