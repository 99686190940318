import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "close_sideoverlay",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}
const _hoisted_2 = {
  id: "close",
  transform: "translate(-1337 -91)"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_2441",
      "data-name": "Rechteck 2441",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("g", _hoisted_2, [
      _createElementVNode("path", {
        id: "outline2",
        d: "M1358,113a1,1,0,0,1-1-1V94a1,1,0,0,1,2,0v18A1,1,0,0,1,1358,113Z",
        class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
      }, null, 2),
      _createElementVNode("path", {
        id: "outline1",
        d: "M1352.844,102.293l-4-4a1,1,0,0,0-1.414,1.414l2.293,2.293H1340a1,1,0,0,0,0,2h9.723l-2.293,2.293a1,1,0,1,0,1.414,1.414l4-4A1,1,0,0,0,1352.844,102.293Z",
        class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
      }, null, 2)
    ])
  ]))
}