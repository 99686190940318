import ApiService from "@/common/api_service";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { UserStoreStateInterface } from "../interfaces";
import { UserInterface } from "./interfaces";

export const useUserStore = defineStore({
    id: "userStore",
    state: (): UserStoreStateInterface => ({
        users: [],
    }),
    getters: {},
    actions: {
        /**
         * UPDATE
         */
        async updateUser(user: UserInterface): Promise<AxiosResponse | null> {
            const response = await ApiService.put({
                resource: "users",
                params: user,
                slug: user.id,
            });
            if (response?.status == 200) {
                const userIndex = this.users.findIndex(
                    (item) => item.id == user.id
                );
                this.users[userIndex] = response?.data.data;
            }
            return response;
        },

        /**
         * CREATE
         */
        async createUser(user: UserInterface): Promise<AxiosResponse | null> {
            const response = await ApiService.post({
                resource: "users",
                params: user,
            });

            if (response?.status == 201) {
                this.users.push(response?.data.data);
            }
            return response;
        },

        /**
         * DELETE
         */

        async deleteUser(user: UserInterface): Promise<AxiosResponse | null> {
            const response = await ApiService.delete({
                resource: "users/" + user.id,
            });

            if (response?.status == 200) {
                const userIndex = this.users.findIndex(
                    (item) => item.id == user.id
                );
                if (userIndex > -1) this.users.splice(userIndex, 1);
            }
            return response;
        },

        /**
         * FETCH
         */
        async fetchUsers(): Promise<AxiosResponse | null> {
            const response = await ApiService.get({
                resource: "users",
            });
            this.users = response?.data.data;
            return response;
        },

        async resendEmail(user: UserInterface): Promise<boolean> {
            const response = await ApiService.post({
                resource: "users/" + user.id + "/invitation",
            });
            if (response?.status == 200) {
                return true;
            }
            return false;
        },
    },
});
