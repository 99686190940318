import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex mb-3"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "grid gap-y-1"
}
const _hoisted_4 = { class: "text-primary font-medium pb-6" }
const _hoisted_5 = { class: "text-grey-darkest" }
const _hoisted_6 = { class: "text-grey-dark text-sm mb-2" }
const _hoisted_7 = { class: "text-grey-darkest" }
const _hoisted_8 = { class: "text-grey-dark text-sm mb-2" }
const _hoisted_9 = { class: "text-grey-darkest" }
const _hoisted_10 = { class: "text-grey-dark text-sm mb-5" }
const _hoisted_11 = {
  key: 1,
  class: "h-full w-full flex items-center justify-center"
}
const _hoisted_12 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_default = _resolveComponent("input-default")!
  const _component_input_textarea = _resolveComponent("input-textarea")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_loading_animation = _resolveComponent("loading-animation")!
  const _component_create_norm_diff_container = _resolveComponent("create-norm-diff-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.editNorm.notify_users && _ctx.editNorm.notify_customers)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["h-auto w-auto p-2 pl-4 pr-4 mr-5 transition-all hover:bg-white-dark rounded-md cursor-pointer", `bg-${_ctx.currentView == 'norm' ? 'white-dark' : 'white'} ${
                !_ctx.editNorm.notify_users ? 'hidden' : ''
            } `]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentView = 'norm'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(
                    _ctx.currentView == 'employee'
                        ? 'text-black'
                        : 'text-grey-darkest'
                )
            }, _toDisplayString(_ctx.$t("employees")), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["h-auto w-auto p-2 pl-4 pr-4 transition-all hover:bg-white-dark rounded-md cursor-pointer", `bg-${_ctx.currentView == 'mail' ? 'white-dark' : 'white'} ${
                !_ctx.editNorm.notify_customers ? 'hidden' : ''
            }`]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentView = 'mail'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(
                    _ctx.currentView == 'customer'
                        ? 'text-black'
                        : 'text-grey-darkest'
                )
            }, _toDisplayString(_ctx.$t("customers")), 3)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    (_ctx.currentView == 'mail')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("email_for_norm")), 1),
                _createVNode(_component_input_default, {
                  id: 'index',
                  name: 'index',
                  modelValue: _ctx.mail.subject,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mail.subject) = $event)),
                  type: 'text',
                  label: _ctx.$t('settings.mail.regard')
                }, null, 8, ["modelValue", "label"]),
                _createVNode(_component_input_textarea, {
                  id: 'textInputComment',
                  name: "textInputComment",
                  isResizable: false,
                  modelValue: _ctx.mail.header,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.mail.header) = $event)),
                  label: _ctx.$t('settings.mail.header'),
                  rows: 3
                }, null, 8, ["modelValue", "label"]),
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("norm")), 1),
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.editNorm.type_short_name + " " + _ctx.editNorm.number ?? "-"), 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("title")), 1),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.editNorm.name ?? "-"), 1),
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("comment")), 1),
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.editNorm.annotations ?? "-"), 1),
                _createVNode(_component_input_select, {
                  id: "selectInputstate",
                  name: "selectInputstate",
                  label: _ctx.$t('settings.mail.change_type'),
                  selectItemConfiguration: { valueColumn: 'name' },
                  isMultiSelect: false,
                  isInputDisabled: true,
                  options: _ctx.emailTypes,
                  modelValue: _ctx.mail.change_type,
                  "onUpdate:modelValue": [
                    _cache[4] || (_cache[4] = ($event: any) => ((_ctx.mail.change_type) = $event)),
                    _cache[5] || (_cache[5] = ($event: any) => (
                    _ctx.mail?.change_type?.email_content
                        ? (_ctx.mail.email_content = _ctx.mail.change_type.email_content)
                        : ''
                ))
                  ]
                }, null, 8, ["label", "options", "modelValue"]),
                _createVNode(_component_input_textarea, {
                  id: 'textInputComment',
                  name: "textInputComment",
                  isResizable: false,
                  modelValue: _ctx.mail.email_content,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.mail.email_content) = $event)),
                  label: _ctx.$t('settings.mail.change_text'),
                  rows: 3
                }, null, 8, ["modelValue", "label"]),
                _createVNode(_component_input_textarea, {
                  id: 'textInputComment',
                  name: "textInputComment",
                  isResizable: false,
                  modelValue: _ctx.mail.footer,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.mail.footer) = $event)),
                  label: _ctx.$t('settings.mail.footer'),
                  rows: 3
                }, null, 8, ["modelValue", "label"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_loading_animation, {
                  id: 'loadingAnimation',
                  name: 'loadingAnimation',
                  animationStyle: { radius: '12', animationColor: 'grey' }
                })
              ]))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createVNode(_component_create_norm_diff_container)
        ]))
  ], 64))
}