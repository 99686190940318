
import { computed, defineComponent } from "vue";
import { storeToRefs } from "pinia";
import { useNormStore } from "@/store/norms";
import { useBaseDataStore } from "@/store/settings/baseDataStore";
import { BaseDataInterface } from "@/store/settings/interfaces";

export default defineComponent({
    name: "EmailContainer",
    components: {},
    props: {
        open: {
            type: Boolean,
            required: false,
        },
    },
    emits: ["close"],
    setup() {
        const { editNorm } = storeToRefs(useNormStore());
        const { types, states } = storeToRefs(useBaseDataStore());
        const mappedNorm = computed(() => {
            return {
                index: editNorm.value.index,
                norm_type_id: types.value.find(
                    (type) => type.id == editNorm.value.norm_type_id
                )?.name,
                number: editNorm.value.number,
                title: editNorm.value.name,
                policies: editNorm.value.policies,
                issue_date: editNorm.value.issue_date,
                annotations: editNorm.value.annotations,
                intern_annotations: editNorm.value.intern_annotations,
                norm_status_id: states.value.find(
                    (state) => state.id == editNorm.value.norm_status_id
                )?.name,
                document: editNorm.value.saving_location,
            };
        });
        function displayValue(key: string, value: any) {
            if (key == "policies") {
                const policieNames: string[] = [];
                value.forEach((policie: BaseDataInterface) => {
                    policieNames.push(policie.name ?? "-");
                });
                return String(policieNames);
            }
            return value;
        }

        return {
            mappedNorm,
            displayValue,
        };
    },
});
