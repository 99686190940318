import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ml-2 w-6 flex-shrink-0" }
const _hoisted_2 = ["src", "title"]
const _hoisted_3 = { class: "hidden md:flex w-9 flex-shrink-0" }
const _hoisted_4 = { class: "text-grey-darker truncate text-sm" }
const _hoisted_5 = { class: "hidden md:flex w-17 flex-shrink-0" }
const _hoisted_6 = { class: "text-grey-darker truncate text-sm" }
const _hoisted_7 = { class: "hidden md:flex w-12 flex-shrink-0" }
const _hoisted_8 = { class: "text-grey-darker truncate text-sm" }
const _hoisted_9 = { class: "hidden md:flex w-28 flex-shrink-0" }
const _hoisted_10 = { class: "text-grey-darker truncate text-sm" }
const _hoisted_11 = { class: "w-44 flex-shrink-0" }
const _hoisted_12 = { class: "text-grey-darker truncate text-sm" }
const _hoisted_13 = { class: "text-sm w-full pr-2 text-left overflow-hidden" }
const _hoisted_14 = { class: "text-grey-darker w-full truncate text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["hidden md:flex items-center gap-3 h-9 bg-white-dark hover:bg-grey-lightest rounded-md cursor-pointer flex-shrink-0 overflow-hidden w-full", `border-l-2 transform transition-all ${
            _ctx.editNorm.id == _ctx.item.id
                ? 'border-primary bg-grey-lightest hover:bg-grey-lighter'
                : 'border-transparent'
        }`])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.item.state_icon_url,
        alt: "status",
        title: _ctx.item.state_label
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.item.index), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.item.policy_short_names), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.item.status_short_name), 1)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.item.type_short_name), 1)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.item.number), 1)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.item.name), 1)
    ])
  ], 2))
}