
import IconCloudDownload from "@/components/icons/IconCloudDownload.vue";
import IconClose from "@/components/icons/IconClose2.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import IconCheckbox from "@/components/icons/IconCheckbox.vue";
import { defineComponent } from "vue";
import { NormDocumentInterface } from "@/store/norms/interfaces";
import LoadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import { storeToRefs } from "pinia";
import { useNormStore } from "@/store/norms";
import { computed, ref } from "@vue/reactivity";
import { useHelpersStore } from "@/store/helpers";

export default defineComponent({
    name: "VersionContainer",
    props: {
        versionItem: {
            type: Object as () => NormDocumentInterface,
            required: true,
        },
        retracted: {
            type: Boolean,
            required: true,
        },
        date: {
            type: String,
            required: false,
        },
    },
    emits: ["delete"],
    components: {
        IconClose,
        IconCheckbox,
        IconTrash,
        LoadingAnimation,
        IconCloudDownload,
    },
    setup(props) {
        const { editNorm } = storeToRefs(useNormStore());
        const isDownloading = ref(false);
        const { loadingIds } = storeToRefs(useHelpersStore());
        function openNormDocument(event: Event, download = false): void {
            const apiUrl = process.env.VUE_APP_API_URL;
            const url =
                apiUrl +
                "/norms/" +
                editNorm.value.id +
                "/documents/" +
                props.versionItem.id +
                "?download=" +
                (download ? 1 : 0);

            console.log(url);
            window.open(url, "_blank");
        }
        const isDeletingDocument = computed(() => {
            return loadingIds.value.find(
                (element) =>
                    element.name == "delete_document" + props.versionItem.id
            );
        });

        const onDownload = (event: Event) => {
            isDownloading.value = true;
            openNormDocument(event, true);
            setTimeout(() => (isDownloading.value = false), 500);
        };

        return {
            openNormDocument,
            onDownload,
            isDeletingDocument,
            isDownloading,
        };
    },
});
