import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex"
}
const _hoisted_2 = { class: "text-sm text-grey-dark w-96" }
const _hoisted_3 = { class: "text-sm text-grey-dark w-64 ml-1" }
const _hoisted_4 = { class: "text-sm text-grey-dark mr-3.5 ml-1 flex-shrink-0" }
const _hoisted_5 = {
  key: 0,
  class: "h-96 pr-1 overflow-y-auto"
}
const _hoisted_6 = {
  key: 1,
  class: "h-96 pr-1 overflow-y-auto"
}
const _hoisted_7 = {
  key: 0,
  class: "text-center w-full mt-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_checkbox = _resolveComponent("input-checkbox")!
  const _component_contact_item = _resolveComponent("contact-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.tabsNeeded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["h-auto w-auto p-2 pl-4 pr-4 mr-5 transition-all hover:bg-white-dark rounded-md cursor-pointer", `bg-${
                    _ctx.currentView == 'employee' ? 'white-dark' : 'white'
                } ${!_ctx.editNorm.notify_users ? 'hidden' : ''} `]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentView = 'employee'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(
                        _ctx.currentView == 'employee'
                            ? 'text-black'
                            : 'text-grey-darkest'
                    )
            }, _toDisplayString(_ctx.$t("employees")), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["h-auto w-auto p-2 pl-4 pr-4 transition-all hover:bg-white-dark rounded-md cursor-pointer", `bg-${
                    _ctx.currentView == 'customer' ? 'white-dark' : 'white'
                } ${!_ctx.editNorm.notify_customers ? 'hidden' : ''}`]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentView = 'customer'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(
                        _ctx.currentView == 'customer'
                            ? 'text-black'
                            : 'text-grey-darkest'
                    )
            }, _toDisplayString(_ctx.$t("customers")), 3)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex ml-3 items-center", _ctx.tabsNeeded ? 'mt-6 ' : ''])
      }, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("name")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("email")), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("select_all")), 1),
        (_ctx.currentView == 'employee')
          ? (_openBlock(), _createBlock(_component_input_checkbox, {
              key: 0,
              id: "checkbox",
              name: "checkbox",
              class: "-mb-5 mr-2.5",
              modelValue: _ctx.selectAll.employee,
              "onUpdate:modelValue": [
                _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectAll.employee) = $event)),
                _cache[3] || (_cache[3] = ($event: any) => (
                        _ctx.selectAllRecipiens('users', _ctx.selectAll.employee)
                    ))
              ]
            }, null, 8, ["modelValue"]))
          : (_openBlock(), _createBlock(_component_input_checkbox, {
              key: 1,
              id: "checkbox",
              name: "checkbox",
              class: "-mb-5 mr-2.5",
              modelValue: _ctx.selectAll.customer,
              "onUpdate:modelValue": [
                _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectAll.customer) = $event)),
                _cache[5] || (_cache[5] = ($event: any) => (
                        _ctx.selectAllRecipiens('customers', _ctx.selectAll.customer)
                    ))
              ]
            }, null, 8, ["modelValue"]))
      ], 2),
      (_ctx.currentView == 'employee')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userRecipients, (contact, index) => {
              return (_openBlock(), _createBlock(_component_contact_item, {
                key: contact.id,
                contact: contact,
                index: index,
                selectAll: _ctx.selectAll.employee,
                onDeselect: _cache[6] || (_cache[6] = ($event: any) => (_ctx.selectAll.employee = false)),
                onChangeCheckValue: _ctx.changeCheckValue
              }, null, 8, ["contact", "index", "selectAll", "onChangeCheckValue"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customerRecipients, (contact, index) => {
              return (_openBlock(), _createBlock(_component_contact_item, {
                key: contact.id,
                contact: contact,
                index: index,
                selectAll: _ctx.selectAll.customer,
                onDeselect: _cache[7] || (_cache[7] = ($event: any) => (_ctx.selectAll.customer = false)),
                onChangeCheckValue: _ctx.changeCheckValue
              }, null, 8, ["contact", "index", "selectAll", "onChangeCheckValue"]))
            }), 128)),
            (_ctx.customerRecipients.length == 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, " Keine zugewiesenen Kunden vorhanden "))
              : _createCommentVNode("", true)
          ]))
    ])
  ]))
}