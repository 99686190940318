import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "sideContainerHeader",
  class: "flex justify-between w-full items-center sticky -top-0 h-20 left-0 right-0 bg-white"
}
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = {
  id: "sideContainerContent",
  class: "w-full mt-10"
}
const _hoisted_5 = { class: "flex h-auto mb-4" }
const _hoisted_6 = { class: "text-grey-darker text-sm w-32 flex-shrink-0" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_9 = { class: "flex h-auto mb-4" }
const _hoisted_10 = { class: "text-grey-darker text-sm w-32 flex-shrink-0" }
const _hoisted_11 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_12 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_13 = { class: "flex h-auto mb-4" }
const _hoisted_14 = { class: "text-grey-darker text-sm w-32 flex-shrink-0" }
const _hoisted_15 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_16 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_17 = { class: "flex h-auto mb-4" }
const _hoisted_18 = { class: "text-grey-darker text-sm w-32 flex-shrink-0" }
const _hoisted_19 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_20 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_21 = { class: "flex h-auto mb-4" }
const _hoisted_22 = { class: "text-grey-darker text-sm w-32 flex-shrink-0" }
const _hoisted_23 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_24 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_25 = { class: "flex h-auto mb-4" }
const _hoisted_26 = { class: "text-grey-darker text-sm w-32 flex-shrink-0" }
const _hoisted_27 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_28 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_29 = { class: "flex h-auto mb-4" }
const _hoisted_30 = { class: "text-grey-darker text-sm w-32 flex-shrink-0" }
const _hoisted_31 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_32 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_33 = { class: "flex h-auto mb-4" }
const _hoisted_34 = { class: "text-grey-darker text-sm w-32 flex-shrink-0" }
const _hoisted_35 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_36 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_37 = { class: "mt-8" }
const _hoisted_38 = { class: "mb-2" }
const _hoisted_39 = {
  class: "overflow-y-auto h-40",
  id: "customer_list"
}
const _hoisted_40 = ["onClick"]
const _hoisted_41 = { class: "text-grey-darker text-sm" }
const _hoisted_42 = {
  key: 0,
  class: "flex"
}
const _hoisted_43 = {
  key: 1,
  class: "mt-1.5"
}
const _hoisted_44 = {
  key: 0,
  class: "border-t-2 mt-3 border-grey-lightest w-full pt-7"
}
const _hoisted_45 = { class: "text-primary" }
const _hoisted_46 = { class: "pb-5" }
const _hoisted_47 = {
  key: 0,
  class: "absolute top-0 bg-white w-full h-full"
}
const _hoisted_48 = { class: "h-1/2 w-full flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_icon_close = _resolveComponent("icon-close")!
  const _component_icon_container = _resolveComponent("icon-container")!
  const _component_icon_arrow = _resolveComponent("icon-arrow")!
  const _component_loading_animation = _resolveComponent("loading-animation")!
  const _component_version_header_container = _resolveComponent("version-header-container")!
  const _component_version_container = _resolveComponent("version-container")!
  const _component_delete_modal = _resolveComponent("delete-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex-shrink-0 absolute lg:relative top-0 right-0 h-full bg-white p-3 pt-0 pl-5 transform transition-all duration-300", 
            _ctx.open
                ? 'w-full md:w-1/2 lg:w-2/5 overflow-y-auto shadow-dark'
                : 'w-0 -mr-8 overflow-hidden'
        ])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _cache[4] || (_cache[4] = _createElementVNode("img", {
          src: "/logo.png",
          class: "h-7 flex-shrink-0"
        }, null, -1)),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.norm)
            ? (_openBlock(), _createBlock(_component_custom_button, {
                key: 0,
                class: "w-auto",
                id: 'customAccept',
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('editNorm')))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("edit")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_icon_container, {
            id: 'closeIcon',
            class: "ml-2",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_close)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("policy")), 1),
            (_ctx.norm && _ctx.norm.policies)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.norm.policies, (policy) => {
                    return (_openBlock(), _createElementBlock("p", {
                      key: policy.id,
                      class: "text-grey-darker text-sm"
                    }, _toDisplayString((policy.name ?? "") +
                                " - " +
                                (policy.addition ?? "")), 1))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("p", _hoisted_8, "-"))
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t("type")), 1),
            (_ctx.norm && _ctx.norm.type_short_name)
              ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.norm.type_short_name), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_12, "-"))
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t("releaseDate")), 1),
            (_ctx.norm && _ctx.norm.displayed_issue_date)
              ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.norm.displayed_issue_date), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_16, "-"))
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t("number")), 1),
            (_ctx.norm && _ctx.norm.number)
              ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_ctx.norm.number), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_20, "-"))
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.$t("title")), 1),
            (_ctx.norm && _ctx.norm.name)
              ? (_openBlock(), _createElementBlock("p", _hoisted_23, _toDisplayString(_ctx.norm.name), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_24, "-"))
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.$t("comment")), 1),
            (_ctx.norm && _ctx.norm.annotations)
              ? (_openBlock(), _createElementBlock("p", _hoisted_27, _toDisplayString(_ctx.norm.annotations), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_28, "-"))
          ]),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.$t("lceIntern")), 1),
            (_ctx.norm && _ctx.norm.intern_annotations)
              ? (_openBlock(), _createElementBlock("p", _hoisted_31, _toDisplayString(_ctx.norm.intern_annotations), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_32, "-"))
          ]),
          _createElementVNode("div", _hoisted_33, [
            _createElementVNode("p", _hoisted_34, _toDisplayString(_ctx.$t("state")), 1),
            (_ctx.norm && _ctx.norm.status_name)
              ? (_openBlock(), _createElementBlock("p", _hoisted_35, _toDisplayString(_ctx.norm.status_name), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_36, "-"))
          ])
        ]),
        _createElementVNode("div", _hoisted_37, [
          _createElementVNode("p", _hoisted_38, _toDisplayString(_ctx.$t("assigned_customers")), 1),
          _createElementVNode("div", _hoisted_39, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customerList, (customer) => {
              return (_openBlock(), _createElementBlock("div", {
                key: customer.id,
                class: "w-full flex h-auto hover:bg-white-dark rounded-md items-center justify-between p-1 pl-2 cursor-pointer",
                onClick: ($event: any) => (
                            _ctx.$router.push({
                                name: 'CustomerAdministrationDetails',
                                params: { id: customer.id },
                            })
                        )
              }, [
                _createElementVNode("p", _hoisted_41, _toDisplayString(customer.name), 1),
                _createVNode(_component_icon_arrow, { color: 'grey-darker' })
              ], 8, _hoisted_40))
            }), 128))
          ]),
          (!_ctx.customerPagination.endOfList)
            ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                (!_ctx.isCustomersLoading)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      class: "text-primary ml-1 mt-2 text-sm cursor-pointer",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadMoreCustomers()))
                    }, _toDisplayString(_ctx.$t("load_more")), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_43, [
                      _createVNode(_component_loading_animation, {
                        id: 'loadingAnimation',
                        name: 'loadingAnimation',
                        animationStyle: {
                                radius: '12',
                                animationColor: 'primary',
                            }
                      })
                    ]))
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.norm && _ctx.norm.documents && _ctx.norm.documents.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
              _createElementVNode("p", _hoisted_45, _toDisplayString(_ctx.$t("versioning")), 1),
              _createVNode(_component_version_header_container),
              _createElementVNode("div", _hoisted_46, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.norm.documents, (document) => {
                  return (_openBlock(), _createBlock(_component_version_container, {
                    key: document.id,
                    retracted: _ctx.norm.status_name == 'Zurückgezogen',
                    date: _ctx.norm.displayed_issue_date,
                    onDelete: ($event: any) => (_ctx.openDeleteModal(document.id)),
                    versionItem: document
                  }, null, 8, ["retracted", "date", "onDelete", "versionItem"]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
            _createElementVNode("div", _hoisted_48, [
              _createVNode(_component_loading_animation, {
                id: 'loadingAnimation',
                name: 'loadingAnimation',
                animationStyle: { radius: '12', animationColor: 'grey' }
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_component_delete_modal, {
      type: _ctx.$t('document'),
      open: _ctx.isDeleteModalOpen,
      onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeDeleteModal())),
      onDelete: _ctx.deleteDocument
    }, null, 8, ["type", "open", "onDelete"])
  ], 64))
}