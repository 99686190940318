
import DeleteModal from "../../settings/modals/ConfirmDeleteModal.vue";
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import { computed, defineComponent, ref } from "vue";
import IconClose from "@/components/icons/IconClose.vue";
import DeleteNormModal from "../../settings/modals/ConfirmDeleteNormModal.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import InputDefault from "@/components/inputs/inputDefault/InputDefault.vue";
import InputSwitch from "@/components/inputs/inputSwitch/InputSwitch.vue";
import InputSelect from "@/components/inputs/inputSelect/InputSelect.vue";
import InputTextarea from "@/components/inputs/inputTextarea/InputTextarea.vue";
import InputDate from "@/components/inputs/inputDate/InputDate.vue";
import IconUpload from "@/components/icons/IconCloudUpload.vue";
import VersionContainer from "./VersionContainer.vue";
import VersionHeaderContainer from "./VersionHeaderContainer.vue";
import LoadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import { storeToRefs } from "pinia";
import { useNormStore } from "@/store/norms";
import { useBaseDataStore } from "@/store/settings/baseDataStore";
import { useHelpersStore } from "@/store/helpers";
import { useRouter } from "vue-router";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "addNormContainer",
    components: {
        InputDefault,
        InputSwitch,
        InputSelect,
        InputTextarea,
        VersionContainer,
        InputDate,
        VersionHeaderContainer,
        LoadingAnimation,
        IconUpload,
        IconClose,
        DeleteNormModal,
        IconTrash,
        CustomButton,
        DeleteModal,
    },
    emits: ["close", "notify_customers", "notify_users"],
    setup(props, ctx) {
        const {
            editNorm,
            customerList,
            uploadedDocument,
            uploadedDocumentDate,
        } = storeToRefs(useNormStore());
        const { loadingIds } = storeToRefs(useHelpersStore());
        const { setLoadingId, removeLoadingId, setToastMessage } =
            useHelpersStore();
        const { policies, types, states } = storeToRefs(useBaseDataStore());
        const { deleteNorm, deleteNormDocument } = useNormStore();
        const fileInput = ref();
        const fileDate = ref();
        const toDeleteDocument = ref<number>();
        const isDeleteModalOpen = ref(false);
        const { t } = useI18n();

        const isLoading = computed(() => {
            return loadingIds.value.some(
                (id) => id.id == "upload_norm_document"
            );
        });
        const isDeletingDocument = computed(() => {
            return loadingIds.value.find(
                (element) => element.name == "delete_document"
            );
        });

        async function inputChangeEvent(event: Event): Promise<void> {
            const target = event.target as HTMLInputElement;
            const files: FileList | null = target.files;

            if (files) {
                uploadedDocument.value = files[0];
                editNorm.value.saving_location = files[0].name;
            }
        }
        const isDeleteNormModalOpen = ref(false);
        function openDeleteModal(documentId: number) {
            toDeleteDocument.value = documentId;
            isDeleteModalOpen.value = true;
        }

        function closeDeleteModal() {
            toDeleteDocument.value = -1;
            isDeleteModalOpen.value = false;
        }

        function openDeleteNormModal() {
            isDeleteNormModalOpen.value = true;
        }
        const router = useRouter();
        async function handleDeleteNorm() {
            if (editNorm.value.id) {
                setLoadingId("confirm-delete-button");
                const response = await deleteNorm();
                ctx.emit("close");
                if (response) {
                    router.push("/");
                }
                removeLoadingId("confirm-delete-button");
            }
            isDeleteNormModalOpen.value = false;
        }

        async function deleteDocument(): Promise<void> {
            if (!isDeletingDocument.value && toDeleteDocument.value) {
                setLoadingId("confirm-delete-button");
                const succeeded = await deleteNormDocument(
                    toDeleteDocument.value
                );
                if (succeeded) {
                    editNorm.value.documents = editNorm.value.documents?.filter(
                        (document) => document.id != toDeleteDocument.value
                    );
                    setToastMessage(
                        ToastMessageType.SUCCESS,
                        t("delete_document_succeeded")
                    );
                } else {
                    setToastMessage(
                        ToastMessageType.ERROR,
                        t("delete_document_failed")
                    );
                }
                removeLoadingId("confirm-delete-button");
                closeDeleteModal();
            }
        }

        return {
            policies,
            types,
            states,
            editNorm,
            customerList,
            inputChangeEvent,
            fileInput,
            isLoading,
            fileDate,
            uploadedDocumentDate,
            uploadedDocument,
            openDeleteNormModal,
            handleDeleteNorm,
            isDeleteNormModalOpen,
            toDeleteDocument,
            isDeleteModalOpen,
            openDeleteModal,
            closeDeleteModal,
            deleteDocument,
        };
    },
});
