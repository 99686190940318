
import MainContainer from "@/components/layout/mainContainer/MainContainer.vue";
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import IconFilter from "@/components/icons/IconFilter.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import IconClose from "@/components/icons/IconClose.vue";
import InputSearch from "@/components/inputs/inputSearch/InputSearch.vue";
import InputSelect from "@/components/inputs/inputSelect/InputSelect.vue";
import DropDown from "@/components/helpers/dropDown/DropDown.vue";
import FilterItem from "./containers/FilterItemContainer.vue";
import ItemList from "./containers/ItemList.vue";
import SideContainer from "./containers/SideContainer.vue";
import IconContainer from "@/components/helpers/iconContainer/IconContainer.vue";
import AddNormModal from "./modals/AddNormModal.vue";

import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useNormStore } from "@/store/norms";
import { useHelpersStore } from "@/store/helpers";
import { cloneData } from "@/common/cloneData";
import { useRoute, useRouter } from "vue-router";
import { useBaseDataStore } from "@/store/settings/baseDataStore";
import { BaseDataInterface } from "@/store/settings/interfaces";

export default defineComponent({
    name: "normAdministration",
    components: {
        MainContainer,
        CustomButton,
        IconFilter,
        InputSearch,
        DropDown,
        IconContainer,
        IconPlus,
        InputSelect,
        FilterItem,
        SideContainer,
        ItemList,
        AddNormModal,
        IconClose,
    },
    setup() {
        const { norm, editNorm, normList, customerPagination, params } =
            storeToRefs(useNormStore());
        const { fetchNormData, fetchNormCustomers, fetchNormDocuments } =
            useNormStore();
        const { setLoadingId, removeLoadingId } = useHelpersStore();
        const {
            fetchBaseDataPolicies,
            fetchBaseDataTypes,
            fetchBaseDataStatus,
        } = useBaseDataStore();
        const { policies, types } = storeToRefs(useBaseDataStore());
        const openAddNorm = ref<boolean>(false);
        const openDetails = ref<boolean>(false);
        const typesTextFilter = ref("");
        const policiesTextFilter = ref("");

        const route = useRoute();
        const router = useRouter();

        const filteredTypes = computed(() => {
            return types.value.filter((type) =>
                type.name
                    ?.toLowerCase()
                    .includes(typesTextFilter.value.toLowerCase())
            );
        });

        const filteredPolicies = computed(() => {
            return policies.value.filter((policy) =>
                policy.name
                    ?.toLowerCase()
                    .includes(policiesTextFilter.value.toLowerCase())
            );
        });

        function updatePolicySearchValue(value: string) {
            policiesTextFilter.value = value;
        }

        function updateTypeSearchValue(value: string) {
            typesTextFilter.value = value;
        }

        watch(
            () => route.path,
            () => {
                const id = route.params.id?.toString();
                const routeName = route.name?.toString();
                if (
                    routeName == "NormAdministration" ||
                    routeName == "NormAdministrationDetails"
                ) {
                    if (!id) {
                        openDetails.value = false;
                        editNorm.value = {
                            notify_customers: false,
                            notify_users: false,
                        };
                    } else {
                        openNormDetails(id);
                    }
                }
            }
        );

        interface FilterDto {
            value?: BaseDataInterface;
            type: string;
            index: number;
        }
        const combinedFilter = computed(() => {
            let filters: FilterDto[] = [];
            params.value.policy_ids?.forEach((policy_id, index: number) => {
                const policy = policies.value.find(
                    (policy) => policy.id == policy_id
                );
                filters.push({ value: policy, type: "policy", index: index });
            });
            params.value.type_ids?.forEach((type_id, index: number) => {
                const type = types.value.find((type) => type.id == type_id);
                filters.push({ value: type, type: "type", index: index });
            });
            return filters;
        });

        async function openNormDetails(normId: string | number) {
            setLoadingId("norm_details");
            openDetails.value = true;
            customerPagination.value.page = 1;
            customerPagination.value.endOfList = false;
            const response = await fetchNormData(normId);
            if (response?.status == 200) {
                await Promise.all([
                    fetchNormDocuments(),
                    fetchNormCustomers(normId),
                ]);
                customerPagination.value.page++;
            } else {
                openDetails.value = false;
                router.push({ name: "NormAdministration" });
            }
            removeLoadingId("norm_details");
        }

        function clearAllFilters() {
            params.value.policy_ids = [];
            params.value.type_ids = [];
            params.value.search = "";
        }

        function removeFilterItem(item: FilterDto): void {
            if (item.type == "policy") {
                params.value.policy_ids?.splice(item.index, 1);
            } else {
                params.value.type_ids?.splice(item.index, 1);
            }
        }
        function addNormSuccess(): void {
            openAddNorm.value = false;
            openDetails.value = true;
        }

        function setEditNorm(): void {
            openAddNorm.value = true;
            editNorm.value = cloneData(norm.value);
        }

        function openAddNormModal(): void {
            openDetails.value = false;
            router.push({ name: "NormAdministration" });
            editNorm.value = { notify_customers: false, notify_users: false };
            openAddNorm.value = true;
        }

        function closeNormDetails(): void {
            openDetails.value = false;
            router.push({ name: "NormAdministration" });
        }

        function checkPolicyFilterLength(): void {
            const policyFilterLength = params.value.policy_ids?.length;
            if (policyFilterLength && policyFilterLength > 3) {
                params.value.policy_ids?.pop();
            }
        }

        function checkTypeFilterLength(): void {
            const typeFilterLength = params.value.type_ids?.length;
            if (typeFilterLength && typeFilterLength > 3) {
                params.value.type_ids?.pop();
            }
        }
        onMounted(() => {
            normList.value = [];
            let routeId;
            if (route.params.id) {
                routeId = route.params.id.toString();
            }
            if (routeId) {
                openNormDetails(routeId);
            } else {
                openDetails.value = false;
                editNorm.value = {
                    notify_customers: false,
                    notify_users: false,
                };
            }

            fetchBaseDataPolicies();
            fetchBaseDataTypes();
            fetchBaseDataStatus();
        });

        return {
            openNormDetails,
            router,
            openDetails,
            openAddNorm,
            params,
            removeFilterItem,
            combinedFilter,
            addNormSuccess,
            setEditNorm,
            policies,
            types,
            openAddNormModal,
            closeNormDetails,
            checkPolicyFilterLength,
            checkTypeFilterLength,
            typesTextFilter,
            policiesTextFilter,
            updatePolicySearchValue,
            updateTypeSearchValue,
            filteredTypes,
            filteredPolicies,
            clearAllFilters,
        };
    },
});
