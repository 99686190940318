
import { defineComponent } from "vue";
import { NormListItemInterface } from "@/store/norms/interfaces";
import { storeToRefs } from "pinia";
import { useNormStore } from "@/store/norms";
export default defineComponent({
    name: "ListItemContainer",
    props: {
        item: {
            type: Object as () => NormListItemInterface,
            required: true,
        },
    },
    setup() {
        const { editNorm } = storeToRefs(useNormStore());
        return { editNorm };
    },
});
