import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_add_norm_container = _resolveComponent("add-norm-container")!
  const _component_contact_container = _resolveComponent("contact-container")!
  const _component_contact_user_container = _resolveComponent("contact-user-container")!
  const _component_email_container = _resolveComponent("email-container")!
  const _component_create_norm_information_container = _resolveComponent("create-norm-information-container")!
  const _component_centered_modal = _resolveComponent("centered-modal")!

  return (_openBlock(), _createBlock(_component_centered_modal, {
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'), _ctx.resetData())),
    title: _ctx.$t(_ctx.modalTitle),
    cancelButtonText: _ctx.$t('cancel'),
    mainContainerId: 'norm_overview'
  }, _createSlots({
    actionButton: _withCtx(() => [
      _createVNode(_component_custom_button, {
        id: 'create_norm_button',
        class: "max-w-full",
        isDisabled: _ctx.checkIfDisabled,
        buttonStyle: {
                    plainBorderPressedColor: 'transparent',
                    plainBorderFocusColor: 'transparent',
                },
        onClick: _ctx.actionButtonHelper
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t(_ctx.actionButtonText)), 1)
        ]),
        _: 1
      }, 8, ["isDisabled", "onClick"])
    ]),
    content: _withCtx(() => [
      (!_ctx.chooseContact && !_ctx.sendMail)
        ? (_openBlock(), _createBlock(_component_add_norm_container, {
            key: 0,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
          }))
        : _createCommentVNode("", true),
      (_ctx.chooseContact && !_ctx.sendMail && _ctx.editNorm.id)
        ? (_openBlock(), _createBlock(_component_contact_container, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.chooseContact && !_ctx.sendMail && !_ctx.editNorm.id)
        ? (_openBlock(), _createBlock(_component_contact_user_container, { key: 2 }))
        : _createCommentVNode("", true),
      (_ctx.sendMail && _ctx.editNorm.id)
        ? (_openBlock(), _createBlock(_component_email_container, { key: 3 }))
        : _createCommentVNode("", true),
      (_ctx.sendMail && !_ctx.editNorm.id)
        ? (_openBlock(), _createBlock(_component_create_norm_information_container, { key: 4 }))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.sendMail || _ctx.chooseContact)
      ? {
          name: "prependButton",
          fn: _withCtx(() => [
            _createVNode(_component_custom_button, {
              id: 'back_button',
              class: "max-w-full",
              buttonStyle: {
                    plainBorderPressedColor: 'transparent',
                    plainBorderFocusColor: 'transparent',
                },
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resetForm()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("back")), 1)
              ]),
              _: 1
            })
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["title", "cancelButtonText"]))
}