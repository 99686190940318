
import { computed, defineComponent, onMounted, ref } from "vue";
import CenteredModal from "@/components/layout/modals/CenteredModal.vue";
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import AddNormContainer from "../containers/AddNormContainer.vue";
import ContactContainer from "../containers/ContactContainer.vue";
import ContactUserContainer from "../containers/ContactUserContainer.vue";
import EmailContainer from "../containers/EmailContainer.vue";
import CreateNormInformationContainer from "../containers/CreateNormInformationContainer.vue";
import { useNormStore } from "@/store/norms";
import { storeToRefs } from "pinia";
import { useHelpersStore } from "@/store/helpers";
import { useI18n } from "vue-i18n";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
import { compareObjects } from "@/common/compare_objects";

export default defineComponent({
    name: "addNormModal",
    components: {
        CenteredModal,
        CustomButton,
        AddNormContainer,
        ContactContainer,
        EmailContainer,
        ContactUserContainer,
        CreateNormInformationContainer,
    },
    emits: ["close", "success"],
    setup(props, ctx) {
        const { t } = useI18n();
        const sendMail = ref(false);
        const chooseContact = ref(false);

        const { createNorm, updateNorm, fetchRecipients, fetchNormDocuments } =
            useNormStore();
        const {
            editNorm,
            userRecipients,
            customerRecipients,
            mail,
            norm,
            uploadedDocument,
            uploadedDocumentDate,
        } = storeToRefs(useNormStore());
        const { setLoadingId, removeLoadingId, setToastMessage } =
            useHelpersStore();

        const canSendMail = computed(() => {
            let canSend = false;
            if (
                editNorm.value.notify_customers ||
                editNorm.value.notify_users
            ) {
                canSend = true;
            }
            return canSend;
        });

        const modalTitle = computed(() => {
            let title = "add_norm";
            if (editNorm.value.id) {
                if (sendMail.value || chooseContact.value) {
                    if (
                        editNorm.value.notify_customers &&
                        editNorm.value.notify_users
                    ) {
                        title = "contact_customers_users";
                    } else if (
                        editNorm.value.notify_customers &&
                        !editNorm.value.notify_users
                    ) {
                        title = "contact_customers";
                    } else {
                        title = "contact_users";
                    }
                } else {
                    title = "edit_norm";
                }
            }
            return title;
        });

        const actionButtonText = computed(() => {
            let text = "save";
            if (sendMail.value) {
                text = "send_email";
            } else if (canSendMail.value) {
                text = "continue";
            }
            return text;
        });

        const checkIfDisabled = computed(() => {
            let isDisabled = true;

            if (
                sendMail.value &&
                editNorm.value.id &&
                editNorm.value.notify_customers
            ) {
                //check if button to send email has to be disabled or not
                if (
                    mail.value.header &&
                    mail.value.change_type &&
                    mail.value.email_content &&
                    mail.value.footer &&
                    mail.value.subject
                ) {
                    isDisabled = false;
                }
            } else if (chooseContact.value) {
                //check if button to send choose contacts has to be disabled or not
                let usersDisabled = false;
                let customersDisabled = false;

                usersDisabled = userRecipients?.value.every(
                    (recipient) => !recipient.checked
                );

                customersDisabled = customerRecipients.value.every(
                    (recipient) => !recipient.checked
                );
                //check if disabled matches to chosen notification options
                if (
                    editNorm.value.notify_customers &&
                    editNorm.value.notify_users &&
                    !customersDisabled &&
                    !usersDisabled
                ) {
                    isDisabled = false;
                } else if (
                    editNorm.value.notify_customers &&
                    !editNorm.value.notify_users &&
                    !customersDisabled
                ) {
                    isDisabled = false;
                } else if (
                    !editNorm.value.notify_customers &&
                    editNorm.value.notify_users &&
                    !usersDisabled
                ) {
                    isDisabled = false;
                }
            } else {
                if (
                    String(editNorm.value.index).length > 0 &&
                    editNorm.value.policies &&
                    editNorm.value.issue_date &&
                    editNorm.value.norm_type_id &&
                    editNorm.value.norm_status_id &&
                    editNorm.value.name &&
                    editNorm.value.number
                )
                    isDisabled = false;
            }
            return isDisabled;
        });

        async function actionButtonHelper() {
            if (sendMail.value) {
                createNewNorm();
            } else if (!canSendMail.value) {
                createNewNorm();
            } else if (chooseContact.value) {
                sendMail.value = true;
            } else {
                const diffs = await compareObjects(norm.value, editNorm.value);
                console.log(diffs);

                goToChooseContact();
            }
        }
        function resetData(): void {
            editNorm.value.notify_customers = false;
            editNorm.value.notify_users = false;
            editNorm.value.saving_location = "";
            uploadedDocument.value = null;
            uploadedDocumentDate.value = "";
            norm.value.notify_customers = false;
            norm.value.notify_users = false;
            norm.value.saving_location = "";
            sendMail.value = false;
            chooseContact.value = false;
        }
        async function createNewNorm(): Promise<void> {
            let responseSucceeded = false;
            if (editNorm.value.id) {
                setLoadingId("create_norm_button");
                responseSucceeded = await updateNorm();

                if (responseSucceeded) {
                    setToastMessage(
                        ToastMessageType.SUCCESS,
                        t("update_norm_succeeded")
                    );
                    await fetchNormDocuments();
                } else {
                    setToastMessage(
                        ToastMessageType.ERROR,
                        t("update_norm_failed")
                    );
                }

                removeLoadingId("create_norm_button");
            } else {
                setLoadingId("create_norm_button");
                responseSucceeded = await createNorm();
                if (responseSucceeded) {
                    setToastMessage(
                        ToastMessageType.SUCCESS,
                        t("toast_message.created_norm")
                    );
                } else {
                    setToastMessage(
                        ToastMessageType.ERROR,
                        t("toast_message.created_norm_failed")
                    );
                }

                removeLoadingId("create_norm_button");
            }

            if (responseSucceeded) {
                resetData();
                ctx.emit("success");
            }
        }

        function resetForm() {
            if (sendMail.value) {
                sendMail.value = false;
            } else {
                chooseContact.value = false;
            }
        }

        async function goToChooseContact(): Promise<void> {
            chooseContact.value = true;
            if (editNorm.value.id) {
                await fetchRecipients(
                    editNorm.value,
                    editNorm.value.notify_customers ?? false,
                    editNorm.value.notify_users ?? false
                );
            }
        }

        onMounted(() => {
            uploadedDocument.value = null;
            uploadedDocumentDate.value = "";
        });
        return {
            canSendMail,
            sendMail,
            chooseContact,
            resetData,
            createNewNorm,
            editNorm,
            goToChooseContact,
            checkIfDisabled,
            resetForm,
            modalTitle,
            actionButtonHelper,
            actionButtonText,
        };
    },
});
