import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "font-semibold" }
const _hoisted_2 = { class: "mb-2" }
const _hoisted_3 = { class: "my-3" }
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { class: "font-semibold" }
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = { class: "mb-2" }
const _hoisted_8 = { class: "border-2 border-grey-lightest border-b-0 rounded-t-md" }
const _hoisted_9 = { class: "grid gap-2 grid-cols-5 border-b-0 border-grey-lightest items-center bg-white-dark" }
const _hoisted_10 = { class: "border-r-2 border-grey-lightest h-full items-center flex p-1 col-span-1" }
const _hoisted_11 = { class: "col-span-2 border-r-2 border-grey-lightest h-full items-center flex" }
const _hoisted_12 = { class: "col-span-2" }
const _hoisted_13 = {
  key: 0,
  class: "grid gap-2 grid-cols-5 border-b-2 border-grey-lightest items-center"
}
const _hoisted_14 = { class: "border-r-2 border-grey-lightest h-full items-center flex p-1 col-span-1" }
const _hoisted_15 = { class: "h-full flex flex-shrink-0 w-full items-center col-span-2 border-r-2 border-grey-lightest" }
const _hoisted_16 = { class: "truncate" }
const _hoisted_17 = { class: "h-full flex flex-shrink-0 w-full items-center col-span-2" }
const _hoisted_18 = { class: "truncate" }
const _hoisted_19 = {
  key: 0,
  class: "grid gap-2 grid-cols-5 border-b-2 border-grey-lightest items-center"
}
const _hoisted_20 = { class: "border-r-2 border-grey-lightest h-full items-center flex p-1 col-span-1" }
const _hoisted_21 = { class: "col-span-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("following_changes_norm")) + ":", 1),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "w-full h-0.5 mb-3 mt-2 bg-grey-lightest" }, null, -1)),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("norm_was_edited")) + ":", 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("norm")) + ": " + _toDisplayString(_ctx.mappedNorm.norm_type_id) + " " + _toDisplayString(_ctx.mappedNorm.number), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("issue_date")) + ": " + _toDisplayString(_ctx.mappedNorm.issue_date), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("title")) + ": " + _toDisplayString(_ctx.mappedNorm.title), 1)
    ]),
    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("changes")) + ":", 1),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("field")), 1)
        ]),
        _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("old_value")), 1),
        _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t("new_value")), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.normDiffs, (diffs, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          (
                    diffs.key != 'notify_users' &&
                    diffs.key != 'notify_customers'
                )
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t(diffs.key)), 1)
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.displayValue(diffs.key, diffs.oldValue)), 1)
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.displayValue(diffs.key, diffs.newValue)), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      (_ctx.editNorm.saving_location)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("saving_location")), 1)
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("p", { class: "col-span-2 border-r-2 border-grey-lightest h-full items-center flex" }, null, -1)),
            _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.editNorm.saving_location), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}