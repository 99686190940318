
import { defineComponent } from "vue";
import MiniModal from "@/components/layout/modals/MiniModal.vue";
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";

export default defineComponent({
    name: "confirmDeleteModal",
    components: { MiniModal, CustomButton },
    props: {
        open: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["close", "delete"],
});
