import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-primary font-medium" }
const _hoisted_2 = { class: "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-6 gap-y-1 justify-between mt-5" }
const _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-1 w-full justify-between" }
const _hoisted_4 = { class: "flex justify-between" }
const _hoisted_5 = { class: "text-grey-darkest" }
const _hoisted_6 = { class: "text-sm text-grey" }
const _hoisted_7 = {
  key: 0,
  class: "flex justify-between mt-7 mb-5"
}
const _hoisted_8 = { class: "text-grey-darkest" }
const _hoisted_9 = { class: "text-sm text-grey" }
const _hoisted_10 = { class: "h-auto w-full border-t-2 border-grey-lightest pt-8" }
const _hoisted_11 = { class: "flex items-center" }
const _hoisted_12 = { class: "text-primary font-medium" }
const _hoisted_13 = { class: "mt-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-6 gap-y-1 justify-between" }
const _hoisted_14 = { class: "flex gap-3 w-full col-span-2" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = {
  key: 1,
  class: "w-full border-t-2 mt-3 border-grey-lightest pt-3"
}
const _hoisted_17 = { class: "flex" }
const _hoisted_18 = { class: "text-red" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_default = _resolveComponent("input-default")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_input_date = _resolveComponent("input-date")!
  const _component_input_textarea = _resolveComponent("input-textarea")!
  const _component_input_switch = _resolveComponent("input-switch")!
  const _component_loading_animation = _resolveComponent("loading-animation")!
  const _component_icon_close = _resolveComponent("icon-close")!
  const _component_icon_upload = _resolveComponent("icon-upload")!
  const _component_version_header_container = _resolveComponent("version-header-container")!
  const _component_version_container = _resolveComponent("version-container")!
  const _component_icon_trash = _resolveComponent("icon-trash")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_delete_norm_modal = _resolveComponent("delete-norm-modal")!
  const _component_delete_modal = _resolveComponent("delete-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("norm_data")), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_input_default, {
        id: 'index',
        name: 'index',
        modelValue: _ctx.editNorm.index,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editNorm.index) = $event)),
        isRequired: true,
        type: 'text',
        label: _ctx.$t('index')
      }, null, 8, ["modelValue", "label"]),
      _createVNode(_component_input_select, {
        id: "norm_policy_ids",
        name: "norm_policy_ids",
        label: _ctx.$t('policy'),
        selectItemConfiguration: {
                valueColumn: 'short_name',
                labelColumn: 'name',
            },
        isMultiSelect: true,
        isRequired: true,
        options: _ctx.policies,
        isInputDisabled: true,
        modelValue: _ctx.editNorm.policies,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editNorm.policies) = $event))
      }, null, 8, ["label", "options", "modelValue"]),
      _createVNode(_component_input_select, {
        id: "norm_type_id",
        name: "type_short_name",
        label: _ctx.$t('type'),
        selectItemConfiguration: {
                valueColumn: 'id',
                labelColumn: 'short_name',
            },
        useValueColumnAsValue: true,
        isRequired: true,
        isInputDisabled: true,
        options: _ctx.types,
        modelValue: _ctx.editNorm.norm_type_id,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editNorm.norm_type_id) = $event))
      }, null, 8, ["label", "options", "modelValue"]),
      _createVNode(_component_input_date, {
        id: 'issue_date',
        name: 'displayed_issue_date',
        modelValue: _ctx.editNorm.issue_date,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editNorm.issue_date) = $event)),
        isRequired: true,
        hasMeta: true,
        label: _ctx.$t('releaseDate')
      }, null, 8, ["modelValue", "label"]),
      _createVNode(_component_input_default, {
        id: 'number',
        name: 'number',
        modelValue: _ctx.editNorm.number,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editNorm.number) = $event)),
        isRequired: true,
        type: 'text',
        label: _ctx.$t('number')
      }, null, 8, ["modelValue", "label"]),
      _createVNode(_component_input_select, {
        id: "norm_status_id",
        name: "status_name",
        label: _ctx.$t('state'),
        selectItemConfiguration: {
                valueColumn: 'id',
                labelColumn: 'name',
            },
        useValueColumnAsValue: true,
        isRequired: true,
        isInputDisabled: true,
        options: _ctx.states,
        modelValue: _ctx.editNorm.norm_status_id,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editNorm.norm_status_id) = $event))
      }, null, 8, ["label", "options", "modelValue"])
    ]),
    _createVNode(_component_input_default, {
      id: 'name',
      name: 'name',
      modelValue: _ctx.editNorm.name,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editNorm.name) = $event)),
      isRequired: true,
      type: 'text',
      label: _ctx.$t('title'),
      class: "w-full mb-1"
    }, null, 8, ["modelValue", "label"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_input_textarea, {
        id: 'annotations',
        name: "annotations",
        isResizable: false,
        modelValue: _ctx.editNorm.annotations,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editNorm.annotations) = $event)),
        label: _ctx.$t('comment'),
        rows: 3
      }, null, 8, ["modelValue", "label"]),
      _createVNode(_component_input_textarea, {
        id: 'intern_annotations',
        name: "intern_annotations",
        isResizable: false,
        modelValue: _ctx.editNorm.intern_annotations,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editNorm.intern_annotations) = $event)),
        label: _ctx.$t('lceIntern'),
        rows: 3
      }, null, 8, ["modelValue", "label"])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("inform_worker")), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("inform_worker_text")), 1)
        ]),
        _createVNode(_component_input_switch, {
          id: 'notify_users',
          name: 'notify_users',
          modelValue: _ctx.editNorm.notify_users ?? false,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (
                    _ctx.editNorm.notify_users = !_ctx.editNorm.notify_users
                )),
          tabIndex: 1,
          class: "mt-2"
        }, null, 8, ["modelValue"])
      ]),
      (_ctx.editNorm.id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("inform_customer")), 1),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("inform_customer_text")), 1)
            ]),
            _createVNode(_component_input_switch, {
              id: 'notify_customers',
              name: 'notify_customers',
              modelValue: _ctx.editNorm.notify_customers ?? false,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (
                    _ctx.editNorm.notify_customers = !_ctx.editNorm.notify_customers
                )),
              isDisabled: _ctx.customerList.length == 0,
              tabIndex: 2,
              class: "mt-2"
            }, null, 8, ["modelValue", "isDisabled"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t("documentation_data")), 1),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loading_animation, {
              key: 0,
              id: 'loadingAnimation',
              name: 'loadingAnimation',
              class: "ml-2",
              animationStyle: {
                    radius: '12',
                    animationColor: 'grey',
                }
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_input_date, {
          id: 'document_date',
          name: 'document_date',
          modelValue: _ctx.uploadedDocumentDate,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.uploadedDocumentDate) = $event)),
          hasMeta: true,
          title: _ctx.$t('document_version_date'),
          label: _ctx.$t('file_date')
        }, null, 8, ["modelValue", "title", "label"]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_input_default, {
            id: 'document',
            name: 'document',
            modelValue: _ctx.editNorm.saving_location,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editNorm.saving_location) = $event)),
            isInputDisabled: true,
            class: "w-full",
            label: _ctx.$t('saving_location'),
            onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.fileInput.click()))
          }, {
            append: _withCtx(() => [
              (_ctx.editNorm.saving_location)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "hover:bg-grey-light rounded-md transition-all cursor-pointer",
                    onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (
                                (_ctx.editNorm.saving_location = undefined),
                                    (_ctx.uploadedDocument = null)
                            ), ["stop"]))
                  }, [
                    _createVNode(_component_icon_close, { color: 'grey-dark' })
                  ]))
                : (_openBlock(), _createBlock(_component_icon_upload, {
                    key: 1,
                    class: "cursor-pointer"
                  }))
            ]),
            _: 1
          }, 8, ["modelValue", "label"])
        ])
      ]),
      _createElementVNode("input", {
        id: "file",
        accept: "application/pdf",
        type: "file",
        ref: "fileInput",
        class: "hidden",
        onChange: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.inputChangeEvent && _ctx.inputChangeEvent(...args)))
      }, null, 544)
    ]),
    (_ctx.editNorm && _ctx.editNorm.documents && _ctx.editNorm.documents.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createVNode(_component_version_header_container),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editNorm.documents, (version, index) => {
            return (_openBlock(), _createBlock(_component_version_container, {
              key: version.id,
              index: index,
              versionItem: version,
              retracted: _ctx.editNorm?.status_name == 'Zurückgezogen',
              date: _ctx.editNorm?.displayed_issue_date,
              onDelete: ($event: any) => (_ctx.openDeleteModal(version.id))
            }, null, 8, ["index", "versionItem", "retracted", "date", "onDelete"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.editNorm.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createVNode(_component_custom_button, {
            class: "w-auto",
            id: 'customAccept',
            "is-plain-button": true,
            onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.openDeleteNormModal()))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_icon_trash, { color: 'red' }),
                _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t("delete_norm")), 1)
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_delete_norm_modal, {
      open: _ctx.isDeleteNormModalOpen,
      onClose: _cache[17] || (_cache[17] = ($event: any) => (_ctx.isDeleteNormModalOpen = false)),
      onDelete: _ctx.handleDeleteNorm
    }, null, 8, ["open", "onDelete"]),
    _createVNode(_component_delete_modal, {
      type: _ctx.$t('document'),
      open: _ctx.isDeleteModalOpen,
      onClose: _cache[18] || (_cache[18] = ($event: any) => (_ctx.closeDeleteModal())),
      onDelete: _ctx.deleteDocument
    }, null, 8, ["type", "open", "onDelete"])
  ], 64))
}