
import { computed, defineComponent, onMounted, ref } from "vue";
import ContactItem from "./ContactItemContainer.vue";
import InputCheckbox from "@/components/inputs/inputCheckbox/InputCheckbox.vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/settings/userStore";
import {
    ContactCheckInterface,
    RecipientInterface,
} from "@/store/norms/interfaces";
import { useNormStore } from "@/store/norms";

export default defineComponent({
    name: "contactContainer",
    components: { ContactItem, InputCheckbox },
    props: {
        open: {
            type: Boolean,
            required: false,
        },
    },
    emits: ["close"],
    setup() {
        const { userRecipients, editNorm } = storeToRefs(useNormStore());
        const { users } = storeToRefs(useUserStore());
        const { fetchUsers } = useUserStore();
        const currentView = ref<string>("employee");
        const selectAll = ref({ employee: true });

        const tabsNeeded = computed(() => {
            return (
                editNorm.value.notify_customers && editNorm.value.notify_users
            );
        });

        function changeCheckValue(data: ContactCheckInterface): void {
            if (currentView.value == "employee") {
                userRecipients.value[data.index].checked = data.value;
                if (!data.value) {
                    selectAll.value.employee = false;
                }
            }
        }

        function selectAllRecipiens(view: string, value: boolean): void {
            if (view == "users") {
                userRecipients.value.map(
                    (recipients: RecipientInterface) =>
                        (recipients.checked = value)
                );
            }
        }

        onMounted(async () => {
            await fetchUsers();
            userRecipients.value = [];
            users.value.forEach((user) => {
                userRecipients.value.push({
                    id: user.id ?? -1,
                    name: user.full_name,
                    email: user.email,
                    checked: true,
                });
            });
        });
        return {
            currentView,
            selectAll,
            userRecipients,
            changeCheckValue,
            selectAllRecipiens,
            editNorm,
            tabsNeeded,
        };
    },
});
