import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-sm ml-3 text-grey-darker flex-shrink-0 w-32" }
const _hoisted_2 = { class: "flex w-full mr-16 truncate" }
const _hoisted_3 = { class: "text-sm ml-3 text-grey-darker w-full truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_close = _resolveComponent("icon-close")!
  const _component_icon_checkbox = _resolveComponent("icon-checkbox")!
  const _component_icon_cloud_download = _resolveComponent("icon-cloud-download")!
  const _component_loading_animation = _resolveComponent("loading-animation")!
  const _component_icon_trash = _resolveComponent("icon-trash")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flex w-full relative h-9 bg-white-dark hover:bg-grey-lightest rounded-md items-center mb-1 pl-2 pr-2 cursor-pointer",
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openNormDocument && _ctx.openNormDocument(...args)))
  }, [
    (_ctx.retracted)
      ? (_openBlock(), _createBlock(_component_icon_close, {
          key: 0,
          class: "flex-shrink-0",
          color: "red"
        }))
      : (_ctx.date == _ctx.versionItem.date)
        ? (_openBlock(), _createBlock(_component_icon_checkbox, {
            key: 1,
            class: "flex-shrink-0",
            color: 'primary'
          }))
        : (_openBlock(), _createBlock(_component_icon_close, {
            key: 2,
            class: "flex-shrink-0"
          })),
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.versionItem.date ?? "-"), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.versionItem.file_name ?? "-"), 1)
    ]),
    _createElementVNode("div", {
      class: "hover:bg-grey-light rounded-md transition-all absolute right-9 cursor-pointer",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDownload && _ctx.onDownload(...args)), ["stop"]))
    }, [
      (!_ctx.isDownloading)
        ? (_openBlock(), _createBlock(_component_icon_cloud_download, {
            key: 0,
            color: "grey-dark"
          }))
        : (_openBlock(), _createBlock(_component_loading_animation, {
            key: 1,
            id: 'loadingAnimation',
            name: 'loadingAnimation',
            animationStyle: { radius: '12', animationColor: 'grey' }
          }))
    ]),
    _createElementVNode("div", {
      class: "hover:bg-grey-light rounded-md transition-all absolute right-2 cursor-pointer",
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('delete')), ["stop"]))
    }, [
      (!_ctx.isDeletingDocument)
        ? (_openBlock(), _createBlock(_component_icon_trash, {
            key: 0,
            class: "",
            color: "grey-dark"
          }))
        : (_openBlock(), _createBlock(_component_loading_animation, {
            key: 1,
            id: 'loadingAnimation',
            name: 'loadingAnimation',
            animationStyle: { radius: '12', animationColor: 'grey' }
          }))
    ])
  ]))
}