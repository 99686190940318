import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-sm text-grey-dark truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_close = _resolveComponent("icon-close")!

  return (_openBlock(), _createElementBlock("div", {
    class: "mt-2 w-auto max-w-96 bg-white-dark hover:bg-grey-lightest transition rounded-full flex items-center justify-center h-7 pl-3 pr-2 flex-shrink-0 cursor-pointer",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('removeFilter')))
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.filter.value.name), 1),
    _createVNode(_component_icon_close, {
      color: 'grey-dark',
      class: "transform scale-90 cursor-pointer flex-shrink-0 mt-px"
    })
  ]))
}