import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "checkbox",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1744",
      "data-name": "Rechteck 1744",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "outline",
      d: "M204,95a8,8,0,1,1-8,8,8.009,8.009,0,0,1,8-8m0-2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Z",
      transform: "translate(-192 -91)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2),
    _createElementVNode("path", {
      id: "check",
      d: "M202.5,107a1,1,0,0,1-.707-.293l-3-3a1,1,0,0,1,1.414-1.414l2.293,2.293,5.293-5.293a1,1,0,1,1,1.414,1.414l-6,6A1,1,0,0,1,202.5,107Z",
      transform: "translate(-192 -91)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}