import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "cloud_download",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_2420",
      "data-name": "Rechteck 2420",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "outline2",
      d: "M1085.683,101.5h-1.351v-2a1,1,0,0,0-2,0v2h-1.351a.721.721,0,0,0-.458,1.213l2.351,2.579a.607.607,0,0,0,.916,0l2.351-2.579A.721.721,0,0,0,1085.683,101.5Z",
      transform: "translate(-1071.332 -89.498)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2),
    _createElementVNode("path", {
      id: "outline1",
      d: "M1083.332,95.5a5.018,5.018,0,0,1,4.941,4.284l.246,1.716h1.813a3,3,0,0,1,0,6h-13a4,4,0,0,1-.218-7.986l1.191-.064.51-1.079a5.022,5.022,0,0,1,4.517-2.871m0-2a6.994,6.994,0,0,0-6.325,4.017,6,6,0,0,0,.325,11.983h13a5,5,0,1,0,0-10h-.08a7,7,0,0,0-6.92-6Z",
      transform: "translate(-1071.332 -89.498)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}