import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-semibold" }
const _hoisted_2 = { class: "mb-2" }
const _hoisted_3 = { class: "my-3" }
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { class: "font-semibold" }
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = { class: "mb-2" }
const _hoisted_8 = { class: "border-2 border-grey-lightest border-b-0 rounded-t-md" }
const _hoisted_9 = { class: "grid gap-2 grid-cols-4 border-b-0 border-grey-lightest items-center bg-white-dark" }
const _hoisted_10 = { class: "border-r-2 border-grey-lightest h-full items-center flex p-1 col-span-1" }
const _hoisted_11 = { class: "col-span-3" }
const _hoisted_12 = { class: "grid gap-2 grid-cols-4 border-b-2 border-grey-lightest items-center" }
const _hoisted_13 = { class: "border-r-2 border-grey-lightest h-full items-center flex p-1 col-span-1" }
const _hoisted_14 = { class: "col-span-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("following_changes_norm")) + ":", 1),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "w-full h-0.5 mb-3 mt-2 bg-grey-lightest" }, null, -1)),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("new_norm_was_created_text")) + ":", 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("norm")) + ": " + _toDisplayString(_ctx.mappedNorm.norm_type_id) + " " + _toDisplayString(_ctx.mappedNorm.number), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("issue_date")) + ": " + _toDisplayString(_ctx.mappedNorm.issue_date), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("title")) + ": " + _toDisplayString(_ctx.mappedNorm.title), 1)
    ]),
    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("norm_data")) + ":", 1),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("field")), 1)
        ]),
        _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("value")), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mappedNorm, (value, key) => {
        return (_openBlock(), _createElementBlock("div", { key: key }, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t(key)), 1)
            ]),
            _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.displayValue(key, value)), 1)
          ])
        ]))
      }), 128))
    ])
  ], 64))
}