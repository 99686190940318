
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import IconClose from "@/components/icons/IconCloseSideoverlay.vue";
import IconArrow from "@/components/icons/IconArrowRight.vue";
import IconContainer from "@/components/helpers/iconContainer/IconContainer.vue";
import VersionContainer from "./VersionContainer.vue";
import VersionHeaderContainer from "./VersionHeaderContainer.vue";
import DeleteModal from "../../settings/modals/ConfirmDeleteModal.vue";
import LoadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useHelpersStore } from "@/store/helpers";
import { useNormStore } from "@/store/norms";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";

export default defineComponent({
    name: "SideContainer",
    props: {
        open: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["close", "editNorm"],
    components: {
        CustomButton,
        IconClose,
        IconContainer,
        IconArrow,
        VersionContainer,
        VersionHeaderContainer,
        LoadingAnimation,
        DeleteModal,
    },
    setup() {
        const { t } = useI18n();
        const { loadingIds } = storeToRefs(useHelpersStore());
        const { norm, customerList, customerPagination } = storeToRefs(
            useNormStore()
        );
        const { fetchNormCustomers, deleteNormDocument, deleteNorm } =
            useNormStore();
        const { setLoadingId, removeLoadingId, setToastMessage } =
            useHelpersStore();
        const route = useRoute();

        const toDeleteDocument = ref<number>();
        const isDeleteModalOpen = ref(false);

        const isLoading = computed(() => {
            return loadingIds.value.find(
                (element) => element.name == "norm_details"
            );
        });
        const isCustomersLoading = computed(() => {
            return loadingIds.value.find(
                (element) => element.name == "customers_loading"
            );
        });
        const isDeletingDocument = computed(() => {
            return loadingIds.value.find(
                (element) => element.name == "delete_document"
            );
        });

        async function loadMoreCustomers(): Promise<void> {
            setLoadingId("customers_loading");
            if (!customerPagination.value.endOfList) {
                const normId: string = route.params.id as string;
                await fetchNormCustomers(normId);
                customerPagination.value.page++;
                removeLoadingId("customers_loading");
            }
        }

        function openDeleteModal(documentId: number) {
            toDeleteDocument.value = documentId;
            isDeleteModalOpen.value = true;
        }

        function closeDeleteModal() {
            toDeleteDocument.value = -1;
            isDeleteModalOpen.value = false;
        }

        async function deleteDocument(): Promise<void> {
            if (!isDeletingDocument.value && toDeleteDocument.value) {
                setLoadingId("confirm-delete-button");
                const succeeded = await deleteNormDocument(
                    toDeleteDocument.value
                );
                if (succeeded) {
                    setToastMessage(
                        ToastMessageType.SUCCESS,
                        t("delete_document_succeeded")
                    );
                } else {
                    setToastMessage(
                        ToastMessageType.ERROR,
                        t("delete_document_failed")
                    );
                }
                removeLoadingId("confirm-delete-button");
                closeDeleteModal();
            }
        }

        function handleCustomerScroll() {
            if (!customerPagination.value.endOfList) {
                let element: HTMLElement | null = document.getElementById(
                    "customer_list"
                ) as HTMLElement;
                if (
                    element?.scrollTop + element?.clientHeight >=
                    element?.scrollHeight
                ) {
                    if (customerList.value.length > 5) loadMoreCustomers();
                }
            }
        }

        onMounted(() => {
            const list: HTMLElement | null = document.getElementById(
                "customer_list"
            ) as HTMLElement;
            list?.addEventListener("scroll", handleCustomerScroll);
        });
        onUnmounted(() => {
            const list: HTMLElement | null = document.getElementById(
                "customer_list"
            ) as HTMLElement;
            list?.removeEventListener("scroll", handleCustomerScroll);
        });

        return {
            isLoading,
            norm,
            customerList,
            loadMoreCustomers,
            isCustomersLoading,
            customerPagination,
            deleteDocument,
            openDeleteModal,
            closeDeleteModal,
            isDeleteModalOpen,
        };
    },
});
