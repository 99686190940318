import ApiService from "@/common/api_service";
import { cloneData } from "@/common/cloneData";
import { generateFormData } from "@/common/generate_form_data";
import router from "@/router";
import { AxiosResponse } from "axios";
import { defineStore, storeToRefs } from "pinia";
import { NormStoreStateInterface } from "../interfaces";
import { useBaseDataStore } from "../settings/baseDataStore";
import { useCustomerNormStore } from "../customerNormRelation";
import { NormInterface } from "./interfaces";
import { useRouter } from "vue-router";

const { types, states } = storeToRefs(useBaseDataStore());
const { replaceCustomerNormEntry } = useCustomerNormStore();

export const useNormStore = defineStore({
    id: "norm",
    state: (): NormStoreStateInterface => ({
        norm: { notify_customers: false, notify_users: false },
        editNorm: { notify_customers: false, notify_users: false },
        normList: [],
        params: {
            sort: "index",
            page: 1,
            policy_ids: [],
            type_ids: [],
            search: "",
        },
        normPagination: {
            endOfList: false,
            page: 1,
        },
        customerPagination: {
            endOfList: false,
            page: 1,
        },
        customerList: [],
        customerRecipients: [],
        userRecipients: [],
        mail: {},
        uploadedDocument: null,
        uploadedDocumentDate: "",
        isFetchingNorms: false,
        isFetchingCustomers: false,
    }),
    getters: {},
    actions: {
        /**
         * FETCH
         */
        async fetchNormList(): Promise<void> {
            if (!this.isFetchingNorms) {
                this.isFetchingNorms = true;
                this.params.page = this.normPagination.page;
                const response = await ApiService.get({
                    resource: "norms",
                    params: this.params,
                });
                if (!response?.data.links.next) {
                    this.normPagination.endOfList = true;
                }
                if (this.normPagination.page == 1) {
                    this.normList = [];
                }
                this.normList = this.normList.concat(response?.data.data);
                this.isFetchingNorms = false;
            }
        },

        async fetchNormData(
            normId: string | number
        ): Promise<AxiosResponse | null> {
            const response = await ApiService.get({
                resource: "norms/" + normId,
            });
            if (response?.data.data) {
                this.norm = response?.data.data;
                this.editNorm = cloneData(this.norm);
                this.editNorm.notify_customers = false;
                this.editNorm.notify_users = false;
            }
            return response;
        },

        async fetchRecipients(
            norm: NormInterface,
            notify_customers: boolean,
            notify_users: boolean
        ): Promise<AxiosResponse | null> {
            this.userRecipients = [];
            this.customerRecipients = [];
            const response = await ApiService.get({
                resource: "norms/" + norm.id + "/recipients",
                params: {
                    users: notify_users,
                    customers: notify_customers,
                },
            });
            if (response?.data.data) {
                if (notify_customers) {
                    this.customerRecipients = response?.data.data.customers;
                    this.customerRecipients.map(
                        (recipient) => (recipient.checked = true)
                    );
                }
                if (notify_users) {
                    this.userRecipients = response?.data.data.users;
                    this.userRecipients.map(
                        (recipient) => (recipient.checked = true)
                    );
                }
            }
            return response;
        },

        async fetchNormCustomers(
            normId: string | number
        ): Promise<AxiosResponse | null> {
            if (!this.isFetchingCustomers) {
                this.isFetchingCustomers = true;
                const response = await ApiService.get({
                    resource: "norms/" + normId + "/customers",
                    params: {
                        page: this.customerPagination.page,
                    },
                });
                if (!response?.data.links.next) {
                    this.customerPagination.endOfList = true;
                }
                if (this.customerPagination.page == 1) {
                    this.customerList = [];
                }
                this.customerList = this.customerList.concat(
                    response?.data.data
                );
                this.isFetchingCustomers = false;
                return response;
            }
            return null;
        },

        /**
         * CREATE
         */
        async createNorm(): Promise<boolean> {
            //set variables
            const final_norm = cloneData(this.editNorm);
            const policyIds = this.editNorm.policies?.map(
                (policy) => policy.id
            );
            final_norm.norm_policy_ids = policyIds;
            if (this.editNorm.notify_users) {
                final_norm.user_ids = this.userRecipients
                    .filter((recipient) => recipient.checked)
                    .map((recipient) => recipient.id);
            }
            final_norm.notify_users = this.editNorm.notify_users ? "1" : "0";
            delete final_norm.policies;
            const fd = generateFormData(final_norm, false);
            if (this.uploadedDocumentDate) {
                fd.append("document_date", this.uploadedDocumentDate);
            }
            if (this.uploadedDocument) {
                fd.append("document", this.uploadedDocument);
            }
            //send request
            const response = await ApiService.post({
                resource: "norms",
                params: fd,
            });
            //add norm to list if norm matches filters
            if (response?.status == 200 || response?.status == 201) {
                const responseData: NormInterface = response?.data.data;
                //send toast message
                //check i norm type matches typeIds filter

                if (
                    this.matchesTypeFilter() &&
                    this.matchesTextFilter() &&
                    this.matchesPolicyFilter()
                ) {
                    this.normList.unshift(responseData);
                }
                router.push({
                    name: "NormAdministrationDetails",
                    params: { id: responseData.id },
                });
                return true;
            }

            return false;
        },

        /**
         * functions to check if norm matches current filter
         */

        matchesTypeFilter(): boolean | undefined {
            //check if type filter is set and norm_type_id is given
            if (
                this.params.type_ids?.length != 0 &&
                this.editNorm.norm_type_id
            ) {
                //return result of array.some if given norm_type_id matches any of the given filters
                return this.params.type_ids?.some(
                    (type_id) => type_id == this.editNorm.norm_type_id
                );
            } else {
                //return true if no type filter is given, else false
                return this.params.type_ids?.length == 0;
            }
        },

        matchesPolicyFilter(): boolean | undefined {
            //check if policies filter is set and norm_policies are given
            if (this.params.policy_ids?.length != 0 && this.editNorm.policies) {
                //return result of array.some if given norm_policies matches any of the given filters
                return this.params.policy_ids?.some((policy_id) =>
                    this.editNorm.policies?.some(
                        (policy) => policy.id == policy_id
                    )
                );
            } else {
                //return true if no policy filter is given, else false
                return this.params.policy_ids?.length == 0;
            }
        },

        matchesTextFilter(): boolean | undefined {
            //check if textfilter is given and norm has a name
            if (this.params.search && this.editNorm.name) {
                //check if given norm name includes given textfilter
                return this.editNorm.name
                    ?.toLowerCase()
                    .includes(this.params.search.toLowerCase());
            } else {
                //return true if no textfilter is given, else false
                return !this.params.search;
            }
        },
        async fetchNormDocuments(): Promise<boolean> {
            const response = await ApiService.get({
                resource: "norms/" + this.editNorm.id + "/documents",
            });

            if (response?.status == 200) {
                const responseData = response?.data.data;
                if (responseData) {
                    this.norm.documents = responseData;
                }
                return true;
            } else {
                return false;
            }
        },

        async uploadNormDocument(file: FormData): Promise<boolean> {
            const response = await ApiService.post({
                resource: "norms/" + this.editNorm.id + "/documents",
                params: file,
            });
            if (response?.status == 201) {
                const responseData = response?.data.data;
                if (responseData) {
                    this.editNorm.documents?.unshift(responseData);
                    this.norm.documents?.unshift(responseData);
                }
                return true;
            } else {
                return false;
            }
        },

        async deleteNormDocument(
            documentId: number
        ): Promise<AxiosResponse | null> {
            const response = await ApiService.delete({
                resource:
                    "norms/" + this.editNorm.id + "/documents/" + documentId,
            });
            if (response?.status == 200 && this.norm.id) {
                await this.fetchNormList();
                const documentIndex = this.norm.documents?.findIndex(
                    (document) => document.id == documentId
                );
                if (documentIndex != null && documentIndex > -1) {
                    this.norm.documents?.splice(documentIndex, 1);
                }
            }
            return response;
        },

        async createNormNote(note: string): Promise<boolean> {
            const response = await ApiService.put({
                resource: "norms/" + this.editNorm.id + "/notes",
                params: { notes: note },
            });
            if (response?.status == 200) {
                const responseData = response?.data.data;
                this.editNorm = cloneData(responseData);
                return true;
            }
            return false;
        },

        /**
         * UPDATE
         */
        async updateNorm(): Promise<boolean> {
            //set variables
            const final_norm = cloneData(this.editNorm);

            const policyIds = this.editNorm.policies?.map(
                (policy) => policy.id
            );

            final_norm.norm_policy_ids = policyIds;

            if (this.editNorm.notify_users) {
                final_norm.user_ids = this.userRecipients
                    .filter((recipient) => recipient.checked)
                    .map((recipient) => recipient.id);
            }
            if (this.editNorm.notify_customers) {
                final_norm.customer_ids = this.customerRecipients
                    .filter((recipient) => recipient.checked)
                    .map((recipient) => recipient.id);
            }

            if (this.editNorm.notify_customers) {
                console.log(this.editNorm);

                final_norm.mail_content = cloneData(this.mail);
            } else {
                delete final_norm.mail_content;
            }

            if (this.editNorm.notify_users) {
                final_norm.notify_users =
                    this.editNorm.notify_users === true ? "1" : "0";
            } else {
                final_norm.notify_users = "0";
            }
            if (this.editNorm.notify_customers) {
                final_norm.notify_customers =
                    this.editNorm.notify_customers === true ? "1" : "0";
            } else {
                final_norm.notify_customers = "0";
            }
            delete final_norm.policies;
            const fd = generateFormData(final_norm, true);
            fd.delete("mail_content");
            if (this.editNorm.notify_customers) {
                fd.append("mail_content[]", "");
            }

            if (this.uploadedDocumentDate) {
                fd.append("document_date", this.uploadedDocumentDate);
            }
            if (this.uploadedDocument) {
                fd.append("document", this.uploadedDocument);
            }
            //send request
            const response = await ApiService.post({
                resource: `norms/${this.editNorm.id}`,
                params: fd,
            });

            if (response?.status == 200 || response?.status == 201) {
                if (this.editNorm) {
                    this.norm = cloneData(this.editNorm);
                }
                //replace object with string
                const type = types.value.find(
                    (type) => type.id == this.editNorm.norm_type_id
                );
                const status = states.value.find(
                    (status) => status.id == this.editNorm.norm_status_id
                );

                this.norm.status_name = status?.name;
                this.norm.type_short_name = type?.short_name;
                const responseData: NormInterface = response?.data.data;
                //replace edited element in normlist
                this.norm.type_short_name = responseData.type_short_name;
                let issue_date;
                //build display Date if date is given since response doesnt give new display_issue_date
                if (this.norm.issue_date) {
                    issue_date = new Date(this.norm.issue_date);
                    this.norm.displayed_issue_date =
                        issue_date.toLocaleDateString("de", {
                            timeZone: "CET",
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                        });
                }
                //replace customerNormListEntry if available
                replaceCustomerNormEntry(responseData);
                const normIndex = this.normList.findIndex(
                    (element) => element.id == responseData.id
                );

                if (normIndex != null) {
                    this.normList[normIndex] = responseData;
                } else {
                    this.normList.unshift(responseData);
                }
                //send toast message
                return true;
            }

            return false;
        },

        async deleteNorm(): Promise<boolean> {
            const response = await ApiService.delete({
                resource: "norms/" + this.editNorm.id,
            });
            if (response?.status == 200) {
                this.normList = this.normList.filter(
                    (norm) => norm.id != this.editNorm.id
                );
                return true;
            }
            return false;
        },
    },
});
