
import { defineComponent, ref } from "vue";
import { NormListItemInterface } from "@/store/norms/interfaces";
import { storeToRefs } from "pinia";
import { useNormStore } from "@/store/norms";
import IconArrow from "@/components/icons/IconArrowBottom.vue";
export default defineComponent({
    name: "ListItemContainer",
    components: { IconArrow },
    props: {
        item: {
            type: Object as () => NormListItemInterface,
            required: true,
        },
    },
    setup() {
        const { editNorm } = storeToRefs(useNormStore());
        const isOpen = ref(false);
        return { editNorm, isOpen };
    },
});
