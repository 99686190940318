
import IconClose from "@/components/icons/IconClose.vue";
import { defineComponent } from "vue";

export default defineComponent({
    name: "FilterItemContainer",
    props: {
        filter: {
            type: Object,
            required: true,
        },
    },
    emits: ["removeFilter"],
    components: {
        IconClose,
    },
});
