import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-full h-9 rounded-md items-center mb-1 pl-8 pr-2" }
const _hoisted_2 = { class: "text-sm ml-3 text-grey flex-shrink-0 w-32" }
const _hoisted_3 = { class: "text-sm ml-3 text-grey flex-shrink-0 w-56 truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("file_date")), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("data")), 1)
  ]))
}