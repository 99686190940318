import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-center w-full" }
const _hoisted_2 = { class: "text-lg font-medium" }
const _hoisted_3 = { class: "text-sm text-grey-darkest mt-2" }
const _hoisted_4 = { class: "text-sm text-grey-darkest mt-2" }
const _hoisted_5 = { class: "flex w-full items-center mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_mini_modal = _resolveComponent("mini-modal")!

  return (_ctx.open)
    ? (_openBlock(), _createBlock(_component_mini_modal, {
        key: 0,
        onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
        isCentered: true,
        mainContainerId: 'customer_administration'
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("norm")) + " " + _toDisplayString(_ctx.$t("final_confirm_delete")), 1),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("final_confirm_delete_norm_text_1")), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("final_confirm_delete_norm_text_2")), 1),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_custom_button, {
                class: "w-auto ml-auto mr-6",
                id: 'delete-cancel-button',
                isPlainButton: true,
                buttonStyle: { plainTextColor: 'grey-darker' },
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("cancel")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_custom_button, {
                class: "mr-auto w-24",
                id: 'confirm-delete-button',
                buttonStyle: {
                        standardBackgroundColor: 'red',
                        standardBackgroundHoverColor: 'red-dark',
                        standardBorderPressedColor: 'red-dark',
                        standardBackgroundPressedColor: 'red-dark',
                        standardBorderFocusColor: 'red',
                    },
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('delete')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("delete")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}